<template>
  <el-dialog :close-on-click-modal="false" title="AI生成" v-model="visible" width="500">
    <div v-loading="loading">
      <div>
        <el-icon><Refresh /></el-icon>换一换
      </div>
      <div>

      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup name="AiReplyDialog">
  import {defineExpose} from "vue";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  import { Refresh } from '@element-plus/icons-vue'
  import {chatgptReplyApi} from "@/api/chat";
  import UserCache from "@hview/hview-utils/lib/cache/user";
  const {visible, loading} = useApp();

  const open = (text: string) => {
    visible.value = true;
    if (!text) {
      return;
    }
    loading.value = true;
    chatgptReplyApi({
      userId: UserCache.getUserInfo().id,
      content: text
    }).then((res: any) => {
      console.info(res);
    }).finally(() => {
      loading.value = false;
    });
  }


  defineExpose({
    open
  });

</script>

<style scoped>

</style>