import { RouteRecordRaw } from 'vue-router'

const article: Array<RouteRecordRaw> = [{
    path: '/article',
    meta: {
        title: "文章管理"
    },
    component: () => import("@/views/article/list.vue")
}, {
    path: '/article/:id',
    meta: {
        title: "编辑",
        activeMenu: "/article"
    },
    component: () => import("@/views/article/edit.vue")
}];
export default article;