<!--
  @description: 表单编辑页面
  @author: zhaxianhe
  @version v1.0
-->
<template>
  <el-dialog :close-on-click-modal="false" v-model="visible" :title="uiModel.title" width="500">
    <el-form v-loading="loading" ref="formRef" :model="model" :rules="rules" label-width="auto">
      <el-form-item label="表单名称" prop="title">
        <el-input  v-model="model.title"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="model.status" class="ml-4">
          <el-radio value="normal" size="large">启用</el-radio>
          <el-radio value="hidden"  size="large">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea"v-model="model.remark"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <div v-if="uiModel.type == 'ADD'">
        <el-button link @click="visible = false">暂不创建</el-button>
        <el-button type="primary" :loading="loading" @click="submit">
          下一步<el-icon><Right/></el-icon>
        </el-button>
      </div>
      <div v-else>
        <el-button link @click="toEditPage">跳过此步</el-button>
        <el-button type="primary" plain :loading="loading" @click="updateForm('close')">修改并关闭</el-button>
        <el-button type="primary" :loading="loading" @click="updateForm">
          继续<el-icon><Right/></el-icon>
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup name="VFormDialog">
  import { CircleClose,Right } from '@element-plus/icons-vue'
  import {ref, defineExpose, reactive, defineEmits, watch} from "vue";
  import {useRouter} from "vue-router";
  import type { FormInstance, FormRules } from 'element-plus'
  import {submitFormApi, updateFormApi} from "@/api/doctor";
  import {Notice} from "@hview/hview-vue3/packages";
  const router = useRouter();
  const formRef = ref<FormInstance>();
  const visible = ref<boolean>(false);
  const loading = ref<boolean>(false);
  const emit = defineEmits(["change"]);
  const uiModel = ref({
    title: "创建表单",
    type: 0
  });

  let model = reactive<RuleForm>({
    id: "",
    // 名称
    title: "",
    // 状态
    status: "normal",
    // 备注
    remark: ""
  });

  interface RuleForm {
    id?: string;
    title: string;
    status: string;
    remark: string;
  }


  const rules = reactive<FormRules<RuleForm>>({
    title: [
      { required: true, message: "请输入表单名称", trigger: ["change", "blur"] },
    ],
    status: [
      { required: true, message: "请选择状态", trigger: ["change", "blur"] },
    ]
  });

  watch(visible, (value) => {
    if (!value) {
      formRef.value?.resetFields();
    }
  })

  /**
   * @description 创建表单
   * @param options
   */
  const create = () => {
    uiModel.value.type = "ADD";
    visible.value = true;
  }

  /**
   * @description 修改表单
   * @param id
   */
  const update = (data: any) => {
    model.id = data.id;
    model.title = data.title;
    model.status = data.status;
    model.remark = data.remark || "";
    uiModel.value.type = "UPDATE";
    uiModel.value.title = "修改表单";
    visible.value = true;
  }

  const updateForm = (type?: string) => {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        loading.value = true;
        updateFormApi(model).then((res: any) => {
          visible.value = false;
          Notice.success("表单修改成功");
          if (type == "close") {
            visible.value = false;
            emit("change");
          } else {
            toEditPage();
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }


  const toEditPage = () => {
    router.push(`/form/${model.id}`);
  }

  /**
   * @description 提交表单
   */
  const submit = () => {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        loading.value = true;
        submitFormApi(model).then((res: any) => {
          const {id} = res.data;
          visible.value = false;
          Notice.success("表单创建成功");
          router.push(`/form/${id}`);
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }
  defineExpose({
    create,
    update
  });
</script>

<style scoped>

</style>