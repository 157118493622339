<template>
  <h-container isBack isTable title="成员列表">
    <template #extra-header>
      <div class="flex-1 text-align-right">
        <el-button type="primary"><i class="iconfont icon-tianjiahuanzhe m-r-5"></i>添加患者</el-button>
      </div>
    </template>
    <div v-if="uiModel.teamInfo">
      <el-alert type="success" :closable="false">
        <template #title>
          <span>当前社区：{{uiModel.teamInfo.name}}</span>
          <span class="m-l-20">社区患者数：{{totalRow}}</span>
        </template>
      </el-alert>
    </div>
    <div class="m-t-15">
      <el-form ref="formRef" :model="model" :inline="true" label-width="auto">
        <el-form-item label="患者名称" prop="keywords">
          <el-input v-model="model.keywords" class="w-200" placeholder="请输入患者真实姓名或昵称" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="reload(load)">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="uiModel.list" stripe style="width: 100%">
      <el-table-column type="index" label="序号" header-align="center" align="center" width="100" />
      <el-table-column prop="user_id" label="用户ID" header-align="center" align="center" width="180" />
      <el-table-column prop="realname" label="真实姓名" header-align="center" align="center" width="180">
        <template #default="scope">
          <h-text v-model="scope.row.realname"></h-text>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" header-align="center" align="center" label="昵称" />
      <el-table-column label="操作" header-align="center" align="center">
        <template #default="scope">
          <el-button type="primary" @click="toDetailDrawerEvent(scope.row)" link>患者详情</el-button>
          <el-button type="primary" @click="toDetailDrawerEvent(scope.row)" link>项目档案</el-button>
          <el-popconfirm @confirm="deleteConfirmEvent(scope.row)" title="移除后不可恢复，是否继续?" cancel-button-text="我再想想" confirm-button-text="确定" width="230">
            <template #reference>
              <el-button type="primary" link>移除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <patient-drawer ref="patientDrawerRef"/>
    </div>
    <template #footer>
      <div class="h-pagination">
        <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
  import {ref} from "vue";
  import {useRoute} from "vue-router";
  import {Notice, usePagination} from "@hview/hview-vue3/packages";
  import {getTeamInfoApi, getTeamMemberInPCApi} from "@/api/team";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  import {removeMemberToTeamApi} from "@/api/doctor";
  import {PatientDrawer} from "@/components/views/patient";
  const {updatePage, getPagination, totalRow, update, reload, page, correctPage} = usePagination();
  const {loading} = useApp();
  const route = useRoute();
  let model = ref({
    teamId: "",
    keywords: ""
  });
  // 定义抽屉
  const patientDrawerRef = ref(null);
  // 设置teamId
  model.value.teamId = route?.query?.id;
  const formRef = ref(null);
  const uiModel = ref({
    list: [],
    teamInfo: null
  });



  /**
   * @description 页码改变事件
   */
  const paginationChangeEvent = (value: number) => {
    update(value, load);
  }

  const toDetailDrawerEvent = (data: any) => {
    patientDrawerRef.value.open(data);
  }

  /**
   * @description 删除表单
   * @param row
   */
  const deleteConfirmEvent = (data: any) => {
    removeMemberToTeamApi({teamId: model.value.teamId, userId: data.user_id}).then(() => {
      Notice.success("删除成功");
      correctPage();
      load();
    });
  }

  /**
   * @description 重置表单
   */
  const resetForm = () => {
    formRef.value.resetFields();
    reload(load);
  }

  /**
   * @description 初始化加载数据
   */
  const load = () => {
    loading.value = true;

    getTeamMemberInPCApi({...model.value,...getPagination()}).then((res: any) => {
      const data = res.data;
      updatePage(data);
      uiModel.value.list = data.data;
    }).finally(() => {
      loading.value = false;
    });
  }
  load();

  /**
   * @description 加载社区详细信息
   */
  const loadTeamInfo = () => {
    console.log(model.value.teamId)
    getTeamInfoApi({
      teamId: model.value.teamId
    }).then((res: any) => {
      uiModel.value.teamInfo = res.data;
    });
  }

  loadTeamInfo();
</script>

<style scoped>

</style>