<template>
  <h-container isTable title="我的社区">
    <el-table v-loading="uiModel.loading" :data="uiModel.list" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" header-align="center" align="center" width="100" />
      <el-table-column label="社区名称" align="center" header-align="center" min-width="230">
        <template #default="scope">
          <el-tooltip effect="dark" :content="scope.row.name" placement="top">
            <div class="text-ellipsis cursor-pointer">{{scope.row.name}}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" align="center" header-align="center" label="创建时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.createtime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" align="center" header-align="center" label="修改时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.updatetime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" header-align="center" fixed="right" width="260">
        <template #default="scope">
          <el-button type="primary" @click="toPatientListPage(scope.row)" link>查看患者列表</el-button>
        </template>
      </el-table-column>
    </el-table>
  </h-container>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router";
import {ref} from "vue";
import {useTimeFormat} from "@hview/hview-vue3/packages";
import {getTeamListByUserApi} from "@/api/team";
const router = useRouter();
const {timeFormat} = useTimeFormat();
const uiModel = ref({
  loading: false,
  list: []
});


/**
 * @description 加载数据
 */
const load = () => {
  uiModel.value.loading = true;
  getTeamListByUserApi().then((res: any) => {
    const data = res.data;
    uiModel.value.list = data;
  }).finally(() => {
    uiModel.value.loading = false;
  });
}
load();

/**
 * @description 跳转到患者列表页面
 * @param data
 */
const toPatientListPage = (data: any) => {
  router.push(`/patient/list?id=${data.id}`);
}



</script>

<style scoped>

</style>