<!--
  @description: 表单结果
  @author: zhaxianhe
  @version v1.0
-->
<template>
  <h-container isBack isTable title="表单填写结果">
    <template #extra-header>
      <div class="flex-1 text-align-right">
        <el-button type="primary" @click="isOpenEdit">{{ isEdit ? "关闭编辑" : "开启编辑" }}</el-button>
      </div>
    </template>
    <div class="p-20" v-loading="loading">
      <div class="m-b-20" v-if="uiModel.formResult">
        <div class="h-item-title">基本信息</div>
        <el-descriptions border>
          <el-descriptions-item label="表单ID">{{ uiModel.formResult.form_id }}</el-descriptions-item>
          <el-descriptions-item label="表单名称">{{uiModel.formResult.customform.title}}</el-descriptions-item>
          <el-descriptions-item label="用户ID">{{uiModel.formResult.user_id}}</el-descriptions-item>
          <el-descriptions-item label="用户名称">{{uiModel.formResult.realname || uiModel.formResult.nickname}}</el-descriptions-item>
          <el-descriptions-item label="填写时间">{{timeFormat(uiModel.formResult.createtime, "yyyy-mm-dd hh:MM:ss")}}</el-descriptions-item>
          <el-descriptions-item label="修改时间">{{timeFormat(uiModel.formResult.updatetime, "yyyy-mm-dd hh:MM:ss")}}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div v-if="uiModel.noAuth">
        <el-empty description="当前数据不存在" />
      </div>
      <div v-else>
        <div class="h-item-title">填写结果</div>
        <v-form-render ref="vFormRef">
        </v-form-render>
        <div class="text-align-center" v-if="isEdit">
          <el-button size="large" type="primary" @click="submit">提交</el-button>
        </div>
      </div>
    </div>
  </h-container>
</template>

<script lang="ts" setup>
  import {ref, onMounted} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import {Notice, useTimeFormat} from "@hview/hview-vue3/packages";
  import UserCache from "@hview/hview-utils/lib/cache/user";
  import {getFormInfoApi, getFormResultApi, getFromUserResultListApi, submitUserResultApi} from "@/api/doctor";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  const {timeFormat} = useTimeFormat();
  const route = useRoute();
  const router = useRouter();
  const {loading} = useApp();
  const vFormRef = ref();
  const uiModel = ref({
    formJson: {},
    noAuth: false,
    formResult: null
  });

  /**
   * @description 是否开启编辑模式
   */
  const isEdit = ref<boolean>(false);

  const model = ref({
    // 结果id
    id: "",
    // 表单id
    fid: ""
  });

  const isOpenEdit = () => {
    isEdit.value = !isEdit.value;
    if (isEdit.value) {
      vFormRef.value.enableForm();
    } else {
      vFormRef.value.disableForm();
    }
  }

  /**
   * @description 初始化加载
   */
  const load = () => {
    loading.value = true;
    getFormInfoApi({
      id: model.value.fid
    }).then((res: any) => {
      let content = res.data.content || null;
      if (content) {
        content = content.replace(/&quot;/g,"\"");
        vFormRef.value.setFormJson(JSON.parse(content));
        loadResult();
      } else {
        vFormRef.value.setFormJson({
          widgetList: [],
          formConfig: {}
        });
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  const loadResult = () => {
    getFormResultApi({id: model.value.id}).then((res: any) => {
      let {result} = res.data;
      uiModel.value.formResult = res.data;
      vFormRef.value.disableForm();
      result = result.replace(/&quot;/g,"\"");
      vFormRef.value.setFormData(JSON.parse(result));

    });
  }

  const submit = () => {
    vFormRef.value.getFormData().then(formData => {
      // Form Validation OK
      submitUserResultApi(formData, model.value.id).then(() => {
        Notice.success("修改成功");
        router.back();
      });
    });
  }


  onMounted(() => {
    const {query} = route;
    if (!query.id) {
      uiModel.value.noAuth = true;
      return false;
    } else {
      model.value.id = query.id;
      model.value.fid = query.fid;
      load();
    }

  });

</script>

<style scoped>

</style>