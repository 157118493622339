"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var patient = [{
        path: '/patient',
        meta: {
            title: "患者管理"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/patient/team.vue"); }); }
    }, {
        path: '/patient/list',
        meta: {
            title: "成员列表"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/patient/list.vue"); }); }
    }];
exports.default = patient;
