<template>
  <el-dialog :close-on-click-modal="false" :title="modelType == 'audit' ? '审核' : '审核详情'" v-model="visible" width="750">
    <div v-loading="loading">
      <el-descriptions :column="2" border>
        <el-descriptions-item width="150" label="申请人姓名">
          <h-text v-model="info.realname"/>
        </el-descriptions-item>
        <el-descriptions-item label="昵称">
          <h-text v-model="info.nickname"/>
        </el-descriptions-item>
        <el-descriptions-item label="项目名称">
          <h-text v-model="info.project_name"/>
        </el-descriptions-item>
        <el-descriptions-item label="小组名称">
          <h-text v-model="info.team_name"/>
        </el-descriptions-item>
        <el-descriptions-item :span="2" label="项目描述">
          <h-text v-model="info.project_desc"/>
        </el-descriptions-item>
        <el-descriptions-item label="医生姓名">
          <h-text v-model="info.doctor_realname"/>
        </el-descriptions-item>
        <el-descriptions-item label="医生昵称">
          <h-text v-model="info.doctor_nickname"/>
        </el-descriptions-item>
        <el-descriptions-item label="提交时间">
          {{timeFormat(info.createtime, "yyyy-mm-dd hh:MM:ss")}}
        </el-descriptions-item>
        <el-descriptions-item label="审核时间">
          <span v-if="info.createtime == info.updatetime">--</span>
          <span v-else>{{timeFormat(info.updatetime, "yyyy-mm-dd hh:MM:ss")}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="审核状态">
          <h-dict dictKey="audit_type" mode="text" v-model="info.status"/>
        </el-descriptions-item>
        <el-descriptions-item v-if="info.reason" :span="2" label="审核意见">
          <h-text v-model="info.reason"/>
        </el-descriptions-item>
      </el-descriptions>
      <div class="m-t-20" v-if="info.status == 'hidden'">
        <el-form ref="formRef" label-width="auto" :model="model" :rules="rules">
          <el-form-item label="审核状态" prop="status">
            <el-select v-model="model.status" placeholder="请选择项目">
              <h-dict dictKey="audit_type_edit"/>
            </el-select>
          </el-form-item>
          <el-form-item ref="reasonRef" label="审核意见" prop="reason" :rules="{
            required: model.status == 'rejected' ? true : false,
            message: '请输入审核意见',
            trigger: ['blur', 'change'],
          }">
            <el-input type="textarea" :rows="3"/>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template #footer>
      <el-button @click="visible = false">关闭</el-button>
      <el-button type="primary" size="large" :loading="loading" v-if="info.status == 'hidden'" @click="submit">提交</el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup name="AuditPatientDialog">
import {ref, defineExpose, watch, defineEmits} from "vue";
import {useApp} from "@hview/hview-vue3/packages/hooks";
import {getTeamAddFormInfoApi, teamAddFormApproveApi} from "@/api/kh";
import {Notice, useTimeFormat} from "@hview/hview-vue3/packages";
const {timeFormat} = useTimeFormat();
const {visible, loading} = useApp();
const model = ref({
  id: "",
  userId: "",
  status: "",
  reason: ""
});

/**
 * 详情
 */
const info = ref({});

const formRef = ref();
const reasonRef = ref();

const modelType: string = ref("");

const emits = defineEmits(["on-load"]);

const rules = ref({
  status: [{ required: true, message: "请选择状态", trigger: "change" }]
});

/**
 * @description 监听审核状态变化
 */
watch(() => model.value.status, (value: string) => {
  if (value !== "rejected") {
    reasonRef.value.clearValidate();
  }
});

watch(visible, (value: boolean) => {
  if (!value) {
    if (formRef.value) {
      formRef.value.resetFields();
    }
  }
});


const load = () => {
  loading.value = true;
  getTeamAddFormInfoApi({id: model.value.id, userId: model.value.userId}).then((res: any) => {
    info.value = res.data;
  }).finally(() => {
    loading.value = false;
  });
}

/**
 * @description 打开弹窗
 */
const open = (id: string, userId: string, type: string) => {
  model.value.id = id;
  model.value.userId = userId;
  visible.value = true;
  modelType.value = type;
  load();
}

const submit = () => {
  formRef.value.validate((valid: boolean) => {
    if (valid) {
      teamAddFormApproveApi({
        id: model.value.id,
        status: model.value.status,
        reason: model.value.reason
      }).then(() => {
        Notice.success("提交成功");
        emits("on-load");
        visible.value = false;
      }).finally(() => {
        loading.value = false;
      });
    }
  });
}

defineExpose({
  open
});
</script>

<style scoped>

</style>