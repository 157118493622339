<!--
@description: 图片类型
@author: zhaxianhe
@createTime: 2023-06-21 19:29:19
-->
<template>
  <bubble-template :data="data" padding="0" background-to="none" background-from="none">
    <div @click="previewImage">
      <el-image class="w-100" :zoom-rate="1.2"
                :max-scale="7"
                :min-scale="0.2"
                fit="cover"
                preview-teleported
                :preview-src-list="[getPath(data)]" :src="getPath(data)" lazy />
    </div>
  </bubble-template>

</template>

<script lang="ts" setup name="ImageBubble">
  import {defineProps} from "vue";
  import BubbleTemplate from "./bubble-template";
  import {useImagePath} from "@/hooks/image-path";
  const {cdnpath} = useImagePath();
  defineProps<{
    data: any
  }>();
  const getPath = (data) => {
    let url = JSON.parse(data.content).url;
    return cdnpath(url);
  }
  /**
   * @description 预览图片
   */
  const previewImage = () => {
    // let url = this.parse(this.data.content).url;
  }
</script>

