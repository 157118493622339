import {getApiPath} from "@config/application";

export function useImagePath () {
    const cdnpath = (path: string) => {
        let CDN = getApiPath();
        if (typeof path !== 'string') {
            return '';
        }
        // http开头不需要补全路径
        if (path.indexOf('http') === 0 || path.indexOf('wxfile://') === 0) {
            return path;
        }
        // http开头不需要补全路径
        if (path.indexOf('https') === 0) {
            return path;
        }
        if (path.indexOf('data:image') === 0) {
            return path;
        }
        if (path.charAt(0) !== '/' && CDN[CDN.length - 1] !== '/') {
            return CDN + '/' + path;
        }
        return CDN + path;
    }
    return {
        cdnpath
    }
}