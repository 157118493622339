<template>
  <div>
    <el-result icon="success" title="填写成功" sub-title="您的表单已提交完成"/>
  </div>
</template>

<script>
</script>

<style scoped>

</style>