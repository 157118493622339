
const dictionary = {
    /**
     * 状态类型
     */
    status_type: [{
        label: "启用",
        value: "normal"
    }, {
        label: "禁用",
        value: "hidden"
    }],
    audit_type: [{
        label: "通过",
        value: "agreed"
    },{
        label: "不通过",
        value: "rejected"
    }, {
        label: "待审核",
        value: "hidden"
    }],
    audit_type_edit: [{
        label: "通过",
        value: "agreed"
    },{
        label: "不通过",
        value: "rejected"
    }],
    // 代金券类型1
    coupon_type_1: [{
        label: "普通",
        value: "normal"
    }, {
        label: "专属券",
        value: "exclusive"
    }],
    // 代金券类型2
    coupon_type_2: [{
        label: "代金券",
        value: "cash"
    }, {
        label: "折扣券",
        value: "discount"
    }],
    // 代金券使用范围
    coupon_use_range: [{
        label: "全部商品",
        value: "all"
    }, {
        label: "部分商品",
        value: "part"
    }]
};

export default dictionary;
