<template>
  <div class="p-20" v-loading="loading">
    <div v-if="uiModel.noAuth">
      <el-empty description="当前数据不存在" />
    </div>
    <div v-else>
      <v-form-render ref="vFormRef">
      </v-form-render>
      <div class="text-align-center">
        <el-button size="large" type="primary" @click="submit">提交</el-button>
      </div>
    </div>
  </div>

</template>

<script lang="ts" setup>
  import {ref, onMounted} from "vue";
  import {useRoute, useRouter} from "vue-router";
  import UserCache from "@hview/hview-utils/lib/cache/user";
  import {getFormInfoApi, submitUserResultApi} from "@/api/doctor";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  import {Notice} from "@hview/hview-vue3/packages";
  const route = useRoute();
  const router = useRouter();
  const {loading} = useApp();
  const vFormRef = ref();
  const uiModel = ref({
    formJson: {},
    noAuth: false
  });

  const model = ref({
    id: ""
  });

  /**
   * @description 初始化加载
   */
  const load = () => {
    loading.value = true;
    getFormInfoApi(model.value).then((res: any) => {
      let content = res.data.content || null;
      if (content) {
        content = content.replace(/&quot;/g,"\"");
        vFormRef.value.setFormJson(JSON.parse(content));
      } else {
        vFormRef.value.setFormJson({
          widgetList: [],
          formConfig: {}
        });
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  const submit = () => {
    vFormRef.value.getFormData().then(formData => {
      // Form Validation OK
      console.info(formData)
      submitUserResultApi(formData, model.value.id).then(() => {
        Notice.success("提交成功");
        router.replace("/form/submit-success");
      });
    });
  }


  onMounted(() => {
    const {query} = route;
    if (!query.id) {
      uiModel.value.noAuth = true;
      return false;
    } else {
      model.value.id = query.id;
    }
    if (query.t) {
      UserCache.setToken(query.t);
    }
    load();
  });

</script>

<style scoped>

</style>