import Http, {RequestParams} from "@hview/hview-http";

/**
 * @description 获取历史记录
 * @param params
 */
export const getMessageHomeListApi = () => Http.post("/api/v2/chat/getMessageHomeList_v2");

/**
 * @description 获取消息历史记录
 * @param params
 */
export const getMessageHistoryApi = (params: RequestParams) => Http.post("/api/v2/chat/getMessageHistory", params);

/**
 * @description 获取群组历史列表
 * @param params
 */
export const getGroupHistroyListApi = (params: RequestParams) => Http.post("/api/v2/chat/getGroupHistroyList", params);

/**
 * @description 获取群组对话详细信息
 * @param params
 */
export const getTeamGroupTalkInfoApi = (params: RequestParams) => Http.post("/api/v2/chat/getTeamGroupTalkInfo", params);

/**
 * @description 得到病人的基本信息
 * @param params
 * @return {Promise<*>}
 */
export const getPatientDataApi = (params: RequestParams) => Http.post("/api/v2/chat/getPatientData", params);

/**
 * @description 获取常用语
 */
export const getCommonWordsApi = () => Http.post("/api/v2/chat/getCommonWords");

/**
 * @description 新增或修改常用语
 * @param params
 * @return {Promise<unknown>}
 */
export const saveOrUpdateCommonWordsApi = (params) => Http.post("/api/v2/chat/saveCommonWords", params);

/**
 * @description 删除常用语
 * @param params
 * @return {Promise<unknown>}
 */
export const delCommonWordsApi = (params) => Http.post("/api/v2/chat/delCommonWords", params);

/**
 * @description 让ai回复
 * @param params
 * @return {Promise<unknown>}
 */
export const chatgptReplyApi = (params) => Http.post("/api/v2/chat/chatgptReply", params);

/**
 * @description 消息的操作
 * @param params
 * @return {Promise<unknown>}
 */
export const messageActApi = (params) => Http.post("/api/v2/chat/messageAct", params);