<template>
  <h-container isTable title="表单管理">
    <template #extra-header>
      <div class="flex-1 text-align-right">
        <el-button type="primary" @click="createFormEvent">创建表单</el-button>
      </div>
    </template>
    <div>
      <el-form ref="formRef" :model="model" :inline="true" label-width="auto">
        <el-form-item label="表单名称" prop="title">
          <el-input v-model="model.title" placeholder="请输入表单名称" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="model.status" placeholder="请选择表单状态" style="width: 240px">
            <el-option label="启用" value="normal"/>
            <el-option label="禁用" value="hidden"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="reload(load)">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="uiModel.loading" :data="uiModel.list" stripe style="width: 100%">
      <el-table-column prop="id" label="编号" header-align="center" align="center" width="100" />
      <el-table-column prop="title" label="表单名称" width="180" />
      <el-table-column prop="createtime" label="创建时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.createtime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.updatetime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template #default="scope">
          <el-tag v-if="scope.row.status == 'normal'" type="success">启用</el-tag>
          <el-tag v-if="scope.row.status == 'hidden'" type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" header-align="center" fixed="right" width="260">
        <template #default="scope">
          <div class="flex align-items-center">
            <template v-if="scope.row.content">
              <el-button type="primary" @click="viewer(scope.row)" link>查看</el-button>
            </template>
            <template v-else>
              <el-button type="primary" @click="toUpdatePage(scope.row)" link>完善表单</el-button>
            </template>
            <el-popconfirm @confirm="deleteConfirmEvent(scope.row)" title="删除后不可恢复，是否继续?" cancel-button-text="我再想想" confirm-button-text="确定" width="230">
              <template #reference>
                <el-button type="primary" link>删除</el-button>
              </template>
            </el-popconfirm>
            <el-dropdown>
              <span class="el-dropdown-link m-l-10">
                更多
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="updateFormEvent(scope.row)">修改</el-dropdown-item>
                  <el-dropdown-item @click="toCompletePage(scope.row)">查看结果</el-dropdown-item>
<!--                  <el-dropdown-item>可见配置</el-dropdown-item>-->
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <v-form-dialog @change="load" ref="vFormDialog"/>
    <v-form-viewer ref="vFormViewer"/>
    <template #footer>
      <div class="h-pagination">
        <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
  import VFormDialog from "@/components/views/vform/vform-dialog.vue";
  import VFormViewer from "@/components/views/vform/vform-viewer.vue";
  import {usePagination} from "@hview/hview-vue3/packages";
  import {useRouter} from "vue-router";
  import {ref, onMounted} from "vue";
  import {deleteFormApi, getFormListApi} from "@/api/doctor";
  import {Notice, useTimeFormat} from "@hview/hview-vue3/packages";
  const {updatePage, getPagination, totalRow, update, reload, page, correctPage} = usePagination();
  const router = useRouter();
  const {timeFormat} = useTimeFormat();
  const uiModel = ref({
    loading: false,
    list: []
  });

  const model = ref({
    title: "",
    status: ""
  });

  const vFormDialog = ref(null);
  const vFormViewer = ref(null);

  const formRef = ref(null);

  const resetForm = () => {
    formRef.value.resetFields();
    reload(load);
  }
  /**
   * @description 页码改变事件
   */
  const paginationChangeEvent = (value: number) => {
    update(value, load);
  }

  const viewer = (row: any) => {
    vFormViewer.value.open(row);
  }

  /**
   * @description 加载数据
   */
  const load = () => {
    uiModel.value.loading = true;
    getFormListApi({...model.value,...getPagination()}).then((res: any) => {
      const data = res.data;
      updatePage(data);
      uiModel.value.list = data.data;
    }).finally(() => {
      uiModel.value.loading = false;
    });
  }


  /**
   * @description 跳转到结果
   */
  const toCompletePage = (data: any) => {
    router.push(`/form/complete?id=${data.id}`);
  }

  /**
   * @description 创建表单
   */
  const createFormEvent = () => {
    vFormDialog.value.create();
  }

  /**
   * @description 修改表单
   */
  const updateFormEvent = (row: any) => {
    vFormDialog.value.update(row);
  }

  /**
   * @description 跳转到修改页面
   * @param row
   */
  const toUpdatePage = (row: any) => {
    router.push(`/form/${row.id}`);
  }

  /**
   * @description 删除表单
   * @param row
   */
  const deleteConfirmEvent = (row: any) => {
    deleteFormApi({id: row.id}).then(() => {
      Notice.success("删除成功");
      correctPage();
      load();
    });
  }

  onMounted(() => {
    load();
  });
</script>

<style scoped>

</style>