<!--
@description: 卡片消息
@author: zhaxianhe
@createTime: 2023-06-23 22:01:06
-->
<template>
  <div>
    <bubble-template padding="0" color-to="#333" background-to="none" background-from="none" :data="data">
      <!-- 卡片——报告症状 -->
        <div class="card-bubble card-bubble-report" :class="{'border-radius-to': isSender(data.from_user_id)}">
          <template v-if="cardContent.content && cardContent.content.type && cardContent.content.type == 'symptom'">
            <div class="card-bubble-report_title">报告症状</div>
            <div class="card-bubble-report_content">
              <div v-html="cardContent.content.content"></div>
            </div>
          </template>

          <template v-else-if="cardContent.type && cardContent.type == 'goods'">
            <div class="card-bubble-report_title app-text-ellipsis_1">{{cardContent.title}}</div>
            <div class="card-bubble-report_content goods-item">
              <div>
                <img :src="cdnpath(cardContent.image)"/>
              </div>
              <div class="goods_info">
                <div class="goods_title">{{cardContent.goodsTitle}} {{cardContent.subtitle}}</div>
                <div class="goods_price">
                  <div>¥{{cardContent.originalPrice}}</div>
                  <div>¥{{cardContent.price}}</div>
                </div>
              </div>

            </div>
          </template>

          <template v-else>
            <div class="card-bubble-report_title app-text-ellipsis_1">{{cardContent.title}}</div>
            <div class="card-bubble-report_content">
              <text>{{cardContent.content}}</text>
            </div>
          </template>
        </div>
    </bubble-template>
  </div>
</template>

<script lang="ts" setup name="CardBubble">
  import {defineProps, ref} from "vue";
  import BubbleTemplate from "./bubble-template";
  import {useImagePath} from "@/hooks/image-path";
  import {useChat} from "@/components/views/chat/hooks/chat";
  const {isSender} = useChat();
  const {cdnpath} = useImagePath();

  const props = defineProps<{
    data: object
  }>();

  const cardContent = ref(null);

  const getContent = (data) => {
    let content = data.content;
    if (content) {
      content = JSON.parse(content);
    }
    return content;
  }

  cardContent.value = getContent(props.data);
</script>

<style lang="scss" scoped>
.card-bubble {
  width: 350px;
  position: relative;
  font-family: PingFang SC-Medium, PingFang SC;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.04);
  border: 1px solid rgba(180,180,180,0.1);
  padding: 45px 10px 0px 10px;
  // 报告症状
  &-report {
    border-radius: 0px 9px 9px 9px;
    &.border-radius-to {
      border-radius: 9px 0px 9px 9px;
    }
    &_title {
      position: absolute;
      top: 0;
      max-width: 80%;
      padding: 0 20px;
      height: 34px;
      line-height: 34px;
      background: #1CB5A0;
      border-radius: 9px 0px 9px 9px;
      color: #FFFFFF;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      color: #FFFFFF;
    }
    // 内容区域
    &_content {
      min-width: 170px;
      font-size: 15px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      padding: 0px 0px 30px 0px;
      border-bottom: 1px solid #F2F2F2;
      &.goods-item {
        display: flex;
        align-items: center;
        img {
          width: 75px;
          height: 75px;
        }
        .goods_info {
          flex: 1;
          display: flex;
          flex-direction: column;
          .goods_title {
            padding-left: 20px;
            flex: 1;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            font-size: 14px;
            line-height: 18px;
          }
          .goods_price {
            display: flex;
            justify-content: flex-end;
            height: 25px;
            div {
              &:first-child{
                font-size: 15px;
                color: var(--second-font-color);
                text-decoration: line-through;
              }
              &:last-child {
                color: #FF0023;
                margin-left: 15px;
              }
            }
          }
        }

      }
    }

  }
}
</style>
