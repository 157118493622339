<!--
  @description: 气泡模版
-->
<template>
  <div class="bubble">
    <!-- 撤回消息 -->
    <template v-if="data.isrevert == 1">
      <div class="bubble-revert" v-if="isSender(data.from_user_id)">你撤回了一条消息 </div>
      <div class="bubble-revert" v-else>''{{data.from_nickname}}''撤回了一条消息</div>
    </template>
    <!-- 系统消息 -->
    <template v-else-if="data?.type == 'system'">

    </template>
    <!-- 普通消息 -->
    <template v-else>
        <!-- 我自己发出的 -->
        <div v-if="isSender(data.from_user_id)" class="bubble_to bubble_layout">
          <div class="bubble_content">
            <el-dropdown trigger="contextmenu" @visible-change="showEvent">
              <div  class="bubble_to_content" :style="{padding: padding, background: backgroundTo, color: colorTo}">
                <img class="ai-service" src="https://aixin-download.oss-cn-beijing.aliyuncs.com/zby-app/static/service.png" v-if="data.is_ai_reply == 1"/>
                <slot :isTo="true"/>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
<!--                  <el-dropdown-item v-if="data.type == 'text'" @click="toAiReply(data)">让AI回复</el-dropdown-item>-->
                  <el-dropdown-item @click="addCommonWords(data)" v-if="data.type == 'text'">添加常用语</el-dropdown-item>
                  <el-dropdown-item v-if="isRevoke" @click="revoke(data)">撤回</el-dropdown-item>
                  <el-dropdown-item @click="deleteMsg(data)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="bubble_avatar">
            <img mode="aspectFit" v-if="data.from_avatar" :src="data.from_avatar"/>
            <img mode="aspectFit" v-else :src="userInfo.avatar"/>
          </div>
        </div>
        <!-- 接收 -->
        <div v-else class="bubble_from bubble_layout">
          <div class="bubble_avatar">
            <img mode="aspectFit" :src="data.from_avatar"/>
          </div>
          <div class="bubble_content">
            <el-dropdown trigger="contextmenu">
              <div  class="bubble_from_content" :style="{padding: padding, background: backgroundFrom, color: colorFrom}">
                <img class="ai-service" src="https://aixin-download.oss-cn-beijing.aliyuncs.com/zby-app/static/service.png" v-if="data.is_ai_reply == 1"/>
                <slot :isTo="false"/>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
<!--                  <el-dropdown-item v-if="data.type == 'text'" @click="toAiReply(data)">让AI回复</el-dropdown-item>-->
                  <el-dropdown-item @click="deleteMsg(data)"><span class="dropdown-item-delete"></span>删除</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <!-- 引用 -->
        <div v-if="data.ref" class="bubble-quote_wrapper" :class="[isSend(data.from_user_id) ? 'to' : 'from']">
          <div class="bubble-quote_wrapper_item">{{data.ref}}</div>
        </div>
    </template>
  </div>
</template>

<script lang="ts" setup name="BubbleTemplate">
  import {defineProps, withDefaults, ref} from "vue";
  import {useChat} from "@/components/views/chat/hooks/chat";
  import {Message, mitter} from "@hview/hview-vue3/packages";
  import UserCache from "@hview/hview-utils/lib/cache/user";
  import {saveOrUpdateCommonWordsApi} from "@/api/chat";
  import { ElMessageBox } from 'element-plus'
  const {isSender} = useChat();

  /**
   * 是否可以撤回
   */
  const isRevoke = ref<boolean>(false);

  interface Props {
    // 消息数据
    data: any;
    // padding值
    padding?: string;
    // 当前自己发出的气泡颜色
    backgroundTo?: string;
    // 当前自己的气泡中的文字颜色
    colorTo?: string;
    // 对方发进来的气泡颜色
    backgroundFrom?: string;
    // 对方发进来的气泡文字颜色
    colorFrom?: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    padding: "30rpx 24rpx",
    backgroundTo: "#1CB5A0",
    colorTo: "#ffffff",
    backgroundFrom: "#f5f6f8",
    colorFrom: "#333333"
  });
  const userInfo = UserCache.getUserInfo();


  // const toAiReply = (data: any) => {
  //   mitter.emit("ai-reply", data.content);
  //   mitter.emit("on-send-msg", {
  //     type: "aiReply",
  //     data: data
  //   });
  // }
  /**
   * @description 撤回
   */
  const revoke = (data: any) => {
    mitter.emit("on-send-msg", {
      type: "revert",
      data: data
    });
  }

  /**
   * @description 删除消息
   */
  const deleteMsg = (data: any) => {
    mitter.emit("on-send-msg", {
      type: "deleteMsg",
      data: data
    });
  }

  /**
   * @description 重新修改
   */
  // const toRevise = () => {
  //   // 如果是通过getMessageList获取的，content是一个json字符串
  //   try {
  //     let data = JSON.parse(props.data.content);
  //     let content = "";
  //     if (data && typeof data == "object") {
  //       content = data.content;
  //     } else {
  //       content = data;
  //     }
  //     mitter.emit("on-revise", content);
  //   } catch (e) {
  //     mitter.emit("on-revise", props.data.content);
  //   }
  // }

  /**
   * @description 自己发出的消息，右键弹出弹层触发事件
   */
  const showEvent = () => {
    let currentTime = new Date().getTime();
    let createTime = parseInt(props.data.createtime) * 1000;
    // 判断是否在5分钟之内
    if (currentTime - createTime < 1000  * 60 * 5) {
      isRevoke.value = true;
    } else {
      isRevoke.value = false;
    }
  }

  /**
   * @description 添加到常用语
   * @param data
   */
  const addCommonWords = (data: any) => {
    ElMessageBox.confirm(
        '是否将此内容添加到常用语中?',
        '提示',
        {
          type: 'warning',
        }
    ).then(() => {
      const content = data.content;
      saveOrUpdateCommonWordsApi({
        content: content
      }).then(() => {
        Message.success("添加成功");
      });
    });
  }
</script>

<style lang="scss">
.dropdown-item-delete {
  color: var(--el-color-danger);
}
.bubble {
  font-family: PingFang SC-Regular, PingFang SC;
  margin-bottom: 30px;

  // 引用
  &-quote_wrapper {
    display: flex;
    margin-top: 10px;
    &.to {
      margin-right: calc(90px + 15px);
      justify-content: flex-end;
    }
    &.from {
      margin-left: calc(90px + 15px);
    }
    &_item {
      max-width: calc(100% - 90px);
      padding: 12px 14px;
      background: rgba(217, 217, 217, 0.2);
      border-radius: 10px;
      font-size: 25px;
      font-weight: 400;
      color: #666666;
      line-height: 35px;
    }
  }
  // 撤回
  &-revert {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #CBD0D4;
    padding: 5px 0px;
    text {
      margin-left: 15px;
    }
  }
  // 用户头像
  &_avatar {
    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;

    }
    overflow: hidden;
    width: 45px;
    box-sizing: content-box;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.11);
    border: 4px solid #FFFFFF;
  }
  // 消息内容
  &_content {
    min-width: 10px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    max-width: calc(100% - 180px);
    position: relative;
    word-break: break-all;
    .ai-service {
      width: 80px;
      height: 80px;
      position: absolute;
      top: -70px;
      z-index: -1;
      right: 14px;
    }
  }
  // 发出
  &_to {
    justify-content: flex-end;
    &_isread {
      display: flex;
      align-items: flex-end;
      color: var(--second-font-color);
      padding-right: 15px;
    }
    &_content {
      margin-right: 15px;
      color: #FFFFFF;
      font-size: 18px;
      line-height: 22px;
      background: var(--primary-color);
      border-radius: 25px 0px 25px 25px;
      padding: 10px 14px;
      box-sizing: border-box;
      .ai-service {
        left: 14px;
      }
    }
  }
  // 接收
  &_from {
    justify-content: flex-start;
    &_content {
      margin-left: 15px;
      background: #F4F5F7;
      line-height: 22px;
      border-radius: 0px 25px 25px 25px;
      padding: 10px 14px;
      font-size: 18px;
      box-sizing: border-box;
    }
  }
  // 基础布局
  &_layout {
    display: flex;
  }
}
</style>