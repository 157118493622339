<template>
  <div class="message-item cursor-pointer flex align-items-center" :class="{'active': route.query.id == data.user.id}">
    <div class="m-r-20 w-40 h-40 avatar">
      <div class="flex group" v-if="data.category == 'service'">
        <el-avatar class="w-20 h-20" v-for="(item, index) in data.user.avatar" :key="index" :src="item || defautAvatar"/>
      </div>
      <el-avatar v-else class="w-40 h-40" :src="data.user.avatar || defautAvatar"/>
      <div class="unread" v-if="data.unread != '0'">{{data.unread}}</div>
    </div>
    <div class="flex-1">
      <div class="flex align-items-center justify-content-space-between">
        <div class="font-size-18 w-130 text-ellipsis">{{data.user.realname || data.user.nickname}}</div>
        <div class="time font-size-12">{{timeFrom(data.createtime)}}</div>
      </div>
      <div class="text-ellipsis message w-210 m-t-2 font-size-16">
        {{data.message}}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup name="MessageItem">
  import {defineProps, ref} from "vue";
  import {useRoute} from "vue-router";
  import {useTimeFormat} from "@hview/hview-vue3/packages";
  const route = useRoute();
  const defautAvatar = ref("http://app.carecancer.cn/assets/img/avatar.png");
  const {timeFrom} = useTimeFormat();
  defineProps<{
    data: object;
    index: number;
  }>();

</script>

<style lang="scss">
.message-item {
  border-bottom: solid 1px var(--line-color-1);
  padding: 10px 8px;
  transition: all 0.3s;
  overflow: hidden;
  width: 305px;
  box-sizing: border-box;
  .avatar {
    position: relative;
    .unread {
      text-align: center;
      background: #FF0023;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      line-height: 20px;
      right: -10px;
      color: #ffffff;
      font-size: 12px;
    }
  }
  .group {
    flex-wrap: wrap;
    overflow: hidden;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .message {
    color: var(--font-tertiary-color);
  }
  .time {
    color: var(--font-tertiary-color);
  }
  &:hover, &.active {
    background: rgba(var(--primary-color-rgb), 0.1);
    border-radius: 10px;
  }
}
</style>