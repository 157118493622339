"use strict";
/**
 * @description 成人全营养项目接口
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminUpdateCouponsApi = exports.getCouponsInfoApi = exports.adminDistributeCouponsApi = exports.adminGetUserReceivecouponsListApi = exports.adminCreateCouponsApi = exports.getGoodsListApi = exports.getUserListApi = exports.getCouponsListApi = exports.getPublicNoticeInfoApi = exports.savePublicNoticeApi = exports.getPublicNoticeListApi = exports.teamAddFormApproveApi = exports.getTeamAddFormInfoApi = exports.getApproveListApi = exports.certificationApproveApi = exports.getTeamListByProjectApi = exports.getCertificationInfoApi = exports.getCertificationListApi = exports.getProjectInfoApi = exports.createProjectApi = exports.getProjectListApi = void 0;
var hview_http_1 = require("@hview/hview-http");
/**
 * @description 获取项目列表
 */
var getProjectListApi = function () { return hview_http_1.default.post("/api/kh/project/getProjectList"); };
exports.getProjectListApi = getProjectListApi;
/**
 * @description 创建项目
 */
var createProjectApi = function (params) { return hview_http_1.default.post("/api/kh/project/createProject", params); };
exports.createProjectApi = createProjectApi;
/**
 * @description 获取项目信息
 * @param params
 */
var getProjectInfoApi = function (params) { return hview_http_1.default.post("/api/kh/project/projectInfo", params); };
exports.getProjectInfoApi = getProjectInfoApi;
/**
 * @description 获取审核列表
 * @param params
 */
var getCertificationListApi = function (params) { return hview_http_1.default.post("/api/kh/Certification/getCertificationList", params); };
exports.getCertificationListApi = getCertificationListApi;
/**
 * 通过id获取认证详情
 * @param params
 */
var getCertificationInfoApi = function (params) { return hview_http_1.default.post("/api/kh/Certification/getCertificationInfo", params); };
exports.getCertificationInfoApi = getCertificationInfoApi;
/**
 * @description 通过项目ID获取team
 * @param params
 */
var getTeamListByProjectApi = function (params) { return hview_http_1.default.post("/api/kh/project/getTeamListByProject", params); };
exports.getTeamListByProjectApi = getTeamListByProjectApi;
/**
 * @description 审核
 * @param params
 */
var certificationApproveApi = function (params) { return hview_http_1.default.post("/api/kh/Certification/CertificationApprove", params); };
exports.certificationApproveApi = certificationApproveApi;
/**
 * @description 获取审批列表
 * @return {Promise<unknown>}
 */
var getApproveListApi = function (params) { return hview_http_1.default.post("/api/kh/user/getApproveList", params); };
exports.getApproveListApi = getApproveListApi;
/**
 * @description 获取审批详情
 * @param params
 * @return {Promise<unknown>}
 */
var getTeamAddFormInfoApi = function (params) { return hview_http_1.default.post("/api/kh/user/getTeamAddFormInfo", params); };
exports.getTeamAddFormInfoApi = getTeamAddFormInfoApi;
/**
 * @description 审核患者
 * @param params
 */
var teamAddFormApproveApi = function (params) { return hview_http_1.default.post("/api/kh/user/teamAddFormApprove", params); };
exports.teamAddFormApproveApi = teamAddFormApproveApi;
/**
 * @description 查询公告列表
 * @param params
 */
var getPublicNoticeListApi = function (params) { return hview_http_1.default.post("/api/kh/team/getPublicNoticeList", params); };
exports.getPublicNoticeListApi = getPublicNoticeListApi;
/**
 * @description 保存公告
 * @param params
 */
var savePublicNoticeApi = function (params) { return hview_http_1.default.post("/api/kh/team/savePublicNotice", params); };
exports.savePublicNoticeApi = savePublicNoticeApi;
/**
 * @description 查询公告
 * @param params
 */
var getPublicNoticeInfoApi = function (params) { return hview_http_1.default.post("/api/kh/team/getPublicNoticeInfo", params); };
exports.getPublicNoticeInfoApi = getPublicNoticeInfoApi;
/**
 * @description 查询优惠券列表
 * @param params
 */
var getCouponsListApi = function (params) { return hview_http_1.default.post("/api/kh/Coupons/getCouponsList", params); };
exports.getCouponsListApi = getCouponsListApi;
/**
 * @description 获取用户列表
 * @param params
 */
var getUserListApi = function (params) { return hview_http_1.default.post("/api/kh/user/getUserList", params); };
exports.getUserListApi = getUserListApi;
/**
 * @description 获取商品列表
 * @param params
 */
var getGoodsListApi = function (params) { return hview_http_1.default.post("/api/kh/Coupons/getGoodsList", params); };
exports.getGoodsListApi = getGoodsListApi;
/**
 * @description 创建优惠券
 * @param params
 */
var adminCreateCouponsApi = function (params) { return hview_http_1.default.post("/api/kh/Coupons/adminCreateCoupons", params); };
exports.adminCreateCouponsApi = adminCreateCouponsApi;
/**
 * @description 获取领取记录
 * @param params
 */
var adminGetUserReceivecouponsListApi = function (params) { return hview_http_1.default.post("/api/kh/Coupons/adminGetUserReceivecouponsList", params); };
exports.adminGetUserReceivecouponsListApi = adminGetUserReceivecouponsListApi;
/**
 * @description 管理员领取优惠券
 * @param params
 */
var adminDistributeCouponsApi = function (params) { return hview_http_1.default.post("/api/kh/Coupons/adminDistributeCoupons", params); };
exports.adminDistributeCouponsApi = adminDistributeCouponsApi;
/**
 * @description 通过id查询优惠券
 * @param params
 */
var getCouponsInfoApi = function (params) { return hview_http_1.default.post("/api/kh/Coupons/getCouponsInfo", params); };
exports.getCouponsInfoApi = getCouponsInfoApi;
/**
 * @description 修改优惠券
 * @param params
 */
var adminUpdateCouponsApi = function (params) { return hview_http_1.default.post("/api/kh/Coupons/adminUpdateCoupons", params); };
exports.adminUpdateCouponsApi = adminUpdateCouponsApi;
