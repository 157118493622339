<template>
  <el-dialog :close-on-click-modal="false" :title="modelType == 'audit' ? '审核' : '审核详情'" v-model="visible" width="750">
    <el-steps v-if="modelType == 'audit'" style="width: 100%;" :active="stepActive" align-center>
      <el-step title="基础信息" />
      <el-step title="审核" />
    </el-steps>
    <div class="m-t-20 m-b-20" v-loading="loading">
      <div v-if="stepActive == 0">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="真实姓名">
            <h-text v-model="info.realname"/>
          </el-descriptions-item>
          <el-descriptions-item label="昵称">
            <h-text v-model="info.nickname"/>
          </el-descriptions-item>
          <el-descriptions-item label="所属单位">
            <h-text v-model="info.qq"/>
          </el-descriptions-item>
          <el-descriptions-item label="所属科室">
            <h-text v-model="info.works"/>
          </el-descriptions-item>
          <el-descriptions-item label="擅长领域">
            <h-text v-model="info.intro"/>
          </el-descriptions-item>
          <el-descriptions-item label="工作年限">
            <h-text v-model="info.ability"/>
          </el-descriptions-item>
          <el-descriptions-item :span="2" label="审核状态">
            <h-dict v-model="info.status" mode="text" dictKey="audit_type"/>
          </el-descriptions-item>
          <el-descriptions-item v-if="info.memo" :span="2" label="审核意见">
            {{info.memo}}
          </el-descriptions-item>
          <el-descriptions-item label="审核图片">
            <template v-if="auditImages.length > 0">
              <el-image fit="contain" v-for="(item, index) in auditImages" :key="index" class="w-100 h-100 m-r-5" :preview-src-list="auditImages" :src="item" >

              </el-image>
            </template>

            <span v-else>暂无内容</span>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div v-if="stepActive == 1">
        <el-form ref="formRef" label-width="auto" :model="model" :rules="rules">
          <el-form-item label="关联项目" prop="projectId">
            <el-select @change="selectEvent" v-model="model.projectId" placeholder="请选择项目">
              <el-option :label="item.project_name" :value="item.id" v-for="(item, index) in projectList" :key="index"/>
            </el-select>
          </el-form-item>
          <el-form-item label="中心" v-if="model.projectId" prop="teamId">
            <el-select  v-model="model.teamId" placeholder="请选择中心">
              <el-option :label="item.name" :value="item.id" v-for="(item, index) in teamList" :key="index"/>
            </el-select>
          </el-form-item>
          <el-form-item label="审核" prop="status">
            <el-radio-group v-model="model.status">
              <h-dict dictKey="audit_type_edit"/>
            </el-radio-group>
          </el-form-item>
          <el-form-item ref="reasonRef" label="审核意见" prop="reason" :rules="{
            required: model.status == 'rejected' ? true : false,
            message: '请输入审核意见',
            trigger: ['blur', 'change'],
          }">
            <el-input type="textarea" v-model="model.reason" rows="4" placeholder="请输入审核意见"/>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template #footer>

      <template v-if="modelType == 'audit'">
        <el-button link @click="visible = false">取消</el-button>
        <el-button v-if="stepActive == 1" type="primary" @click="stepActive = 0">上一步</el-button>
        <el-button v-if="stepActive == 1" type="primary" :loading="loading" @click="submit">提交</el-button>
        <el-button v-if="stepActive == 0" type="primary" @click="stepActive = 1">下一步</el-button>
      </template>
      <el-button type="primary" @click="visible = false" v-else>关闭</el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup name="AuditDialog">
import {ref, defineExpose, watch, defineEmits} from "vue";
import {useApp, useImage} from "@hview/hview-vue3/packages/hooks";
import {certificationApproveApi, getCertificationInfoApi, getProjectListApi, getTeamListByProjectApi} from "@/api/kh";
import {Notice} from "@hview/hview-vue3/packages";
const {visible, loading} = useApp();
const {getImagePath} = useImage();
const emits = defineEmits(["on-load"]);

/**
 * 审核意见ref
 */
const reasonRef = ref();

const modelType: string = ref("");

/**
 * 表单ref
 */
const formRef = ref();

/**
 *  步骤条选中
 */
const stepActive = ref(0);
const model = ref({
  id: "",
  status: "",
  projectId: "",
  reason: "",
  teamId: ""
});

/**
 * @description 审核图片
 */
const auditImages = ref([]);

/**
 * @description 监听审核状态变化
 */
watch(() => model.value.status, (value: string) => {
  if (value !== "rejected") {
    reasonRef.value.clearValidate();
  }
});

watch(visible, (value: boolean) => {
  if (!value) {
    if (formRef.value) {
      formRef.value.resetFields();
    }
    stepActive.value = 0;
  }
});

/**
 * 校验规则
 */
const rules = ref({
  projectId: [{ required: true, message: "请选择项目", trigger: "change" }],
  teamId: [{ required: true, message: "请选择中心", trigger: "change" }],
  status: [{ required: true, message: "请选择状态", trigger: "change" }]
});

/**
 * 详细信息
 */
const info = ref({
});

/**
 * @description 提交数据至后台
 */
const submit = () => {
  formRef.value.validate((valid: boolean) => {
    if (valid) {
      loading.value = true;
      certificationApproveApi(model.value).then(() => {
        Notice.success("提交成功");
        visible.value = false;
        emits("on-load");
      }).finally(() => {
        loading.value = false;
      })
    }
  });
}

/**
 * 项目列表
 */
const projectList = ref([]);

/**
 * 小组列表
 */
const teamList = ref([]);

/**
 * @description 获取team列表
 */
const getTeamList = () => {
  getTeamListByProjectApi({projectId: model.value.projectId}).then((res: any) => {
    const {data} = res;
    teamList.value = data;
  });
}

const selectEvent = () => {
  model.teamId = "";
  getTeamList();
}

/**
 * @description 加载详情
 */
const load = () => {
  loading.value = true;
  Promise.all([getCertificationInfoApi(model.value), getProjectListApi()]).then((arr: any[]) => {
    const certificationInfo = arr[0].data;
    model.value.projectId = certificationInfo.kh_project_id;
    if (model.value.projectId) {
      getTeamList();
    }
    const project = arr[1].data;
    info.value = certificationInfo;
    const splitImage = certificationInfo.images.split(",");
    auditImages.value = [];
    splitImage.forEach((item: string) => {
      auditImages.value.push(getImagePath(item));
    });
    projectList.value = project;
  }).finally(() => {
      loading.value = false;
  });
}

/**
 * @description 打开弹窗
 */
const open = (id: string, type: string) => {
  model.value.id = id;
  visible.value = true;
  modelType.value = type;
  load();
}


defineExpose({
  open
});
</script>

<style scoped>

</style>