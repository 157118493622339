"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "h-pagination" };
var use_pagination_1 = require("../../../hooks/use-pagination");
exports.default = (0, vue_1.defineComponent)({
    __name: 'index',
    props: {
        total: { default: 0 },
        currentPage: { default: 1 },
        layout: { default: "total, prev, pager, next" }
    },
    emits: ["change"],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var count = (0, use_pagination_1.usePagination)().count;
        var emit = __emit;
        /**
         * @description 页码改变触发事件
         * @param value
         */
        var currentChangeEvent = function (value) {
            emit("change", value);
        };
        return function (_ctx, _cache) {
            var _component_el_pagination = (0, vue_2.resolveComponent)("el-pagination");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createVNode)(_component_el_pagination, {
                    "default-page-size": (0, vue_2.unref)(count),
                    onCurrentChange: currentChangeEvent,
                    "current-page": _ctx.currentPage,
                    background: "",
                    layout: _ctx.layout,
                    total: _ctx.total
                }, null, 8, ["default-page-size", "current-page", "layout", "total"])
            ]));
        };
    }
});
