<template>
  <el-dialog :close-on-click-modal="false" v-model="visible" :title="uiModel.title" width="500">
    <el-form v-loading="loading" ref="formRef" :model="model" :rules="rules" label-width="auto">
      <el-form-item label="常用语" prop="content">
        <el-input rows="6" show-word-limit maxlength="500" type="textarea" placeholder="请输入常用语" v-model="model.content"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button link @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submit">{{model.id ? "修改" : "新增"}}</el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup name="CommonWordsDialog">
  import {ref, defineExpose, reactive, watch, defineEmits} from "vue";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  import type { FormInstance, FormRules } from 'element-plus'
  import {saveOrUpdateCommonWordsApi} from "@/api/chat";
  import {Notice} from "@hview/hview-vue3/packages";
  const emits = defineEmits(["change"]);
  const formRef = ref<FormInstance>();
  const {visible, loading} = useApp();

  const uiModel = ref({
    title: "",
    type: ""
  });

  let model = reactive<RuleForm>({
    id: "",
    // 名称
    content: ""
  });

  interface RuleForm {
    id?: string;
    content: string;
  }


  const rules = reactive<FormRules<RuleForm>>({
    content: [
      { required: true, message: "请输入常用语", trigger: ["change", "blur"] },
    ]
  });

  watch(visible, (value) => {
    if (!value) {
      formRef.value.resetFields();
    }
  })

  /**
   * @description 创建文章
   * @param options
   */
  const create = () => {
    uiModel.value.type = "ADD";
    uiModel.value.title = "新增常用语";
    visible.value = true;
  }

  /**
   * @description 提交
   */
  const submit = () => {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        loading.value = true;
        saveOrUpdateCommonWordsApi(model).then(() => {
          Notice.success(model.id ? "修改成功" : "新增成功");
          visible.value = false;
          emits("change");
        }).finally(() => {
          loading.value = false;
        });
      }
    });
  }

  /**
   * @description 修改文章
   * @param id
   */
  const update = (data: any) => {
    uiModel.value.type = "UPDATE";
    uiModel.value.title = "修改常用语";
    visible.value = true;
    model.id = data.id;
    model.content = data.content;
  }


  defineExpose({create, update});
</script>

<style scoped>

</style>