/**
 * @description:cookie
 */

/**
 * @description:设置cookie值
 * @param key:键
 * @param value:值
 * @param minutes:存储的时间，单位为分钟(默认24小时)
 * @author zhaxianhe
 */
export const set = (key: string, value: any, minutes = 24*60) => {
    let exp: any = new Date();
    exp.setTime(exp.getTime() + minutes * 60 * 1000);
    document.cookie = key + '=' + escape(value) + ';expires=' + exp.toGMTString() + ';path=/';
};

/**
 * @description: 获取cookie值
 * @param key:键
 * @return String
 * @author zhaxianhe
 */
export const get = (key: string) => {
    let reg = new RegExp('(^| )' + key + '=([^;]*)(;|$)');
    let arr = document.cookie.match(reg);
    if (arr) {
        return unescape(arr[2]);
    }
    return null;
};

/**
 * @description:删除cookie值
 * @param key:键
 * @author zhaxianhe
 */
export const remove = (key: any) => {
    let exp: any = new Date();
    exp.setTime(exp.getTime() - 1);
    var value = get(key);
    if (value !== null) {
        document.cookie= key + '=' + value + ';expires=' + exp.toGMTString();
    }
};