/**
 * @description 查询当前用户在哪些team下面
 * @param userId: 用户id
 */
import Http, {RequestParams} from "@hview/hview-http";

/**
 * @description 通过用户查询当前用户加入了多少社区
 * @param params
 */
export const getTeamListByUserApi = (params: RequestParams) => Http.post("/api/v2/team/getTeamListByUser", params);

/**
 * @description 通过team查询通讯录
 * @param params
 */
export const getTeamMemberInPCApi = (params: RequestParams) => Http.post("/api/v2/team/getTeamMemberInPC", params);

/**
 * @description 通过teamid查询team信息
 * @param params
 */
export const getTeamInfoApi = (params: RequestParams) => Http.post("/api/kh/team/getTeamInfo", params);

/**
 * @description 获取team列表
 * @param params
 */
export const getTeamListApi = (params: RequestParams) => Http.post("/api/kh/team/getTeamList", params);

/**
 * @description 添加team
 * @param params
 */
export const addTeamApi = (params: RequestParams) => Http.post("/api/kh/team/addTeam", params);

/**
 * @description 修改team
 * @param params
 */
export const updateTeamApi = (params: RequestParams) => Http.post("/api/kh/team/updateTeam", params);
