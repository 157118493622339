<template>
  <h-container frameless>
    <div class="h-100p flex chat">
      <div class="chat-list bg-white border-radius-10 w-330 m-r-10">
        <chat-message/>
      </div>
      <!-- 对话页面 -->
      <div class="bg-white border-radius-10 flex-1 m-r-10 dialogue">
        <dialogue/>
      </div>
      <!-- 用户基本信息 -->
      <div v-if="showUserInfo" class="bg-white border-radius-10 w-360 m-r-10 user-info p-t-10 p-b-10 box-sizing-border-box">
        <user-info/>
      </div>
    </div>
  </h-container>
</template>

<script lang="ts" setup>
  import {watch, ref, onMounted} from "vue";
  import {useRoute} from "vue-router";
  import {Dialogue, ChatMessage, UserInfo} from "@/components/views/chat";
  const route = useRoute();
  const showUserInfo = ref<boolean>(true);

  watch(route, () => {
    init();
  })

  /**
   * @description 初始化，判断是否显示用户信息，如果是群组聊天窗口则不显示用户信息
   */
  const init = () => {
    const id = route.query.id || "";
    if (!id) {
      return;
    }
    if (id.indexOf("service") != -1) {
      showUserInfo.value = false;
    } else {
      showUserInfo.value = true;
    }
  }

  onMounted(() => {
    init();
  })
</script>

<style lang="scss">
@media(max-width: 1150px) {
  .user-info {
    width: 0;
    .user-info-wrapper {
      display: none;
    }
  }
  .dialogue {
    margin: 0;
  }
}
.chat {
  padding-bottom: 10px;
  box-sizing: border-box;
  .address-search {
    .el-input__wrapper {
      padding-left: 4px !important;
    }
  }
  &-tabs {
    .el-tabs__nav-wrap:after {
      height: 1px;
      transform: scaleY(0.4);
    }
  }
  &-list {
  }
  .user-info {
    overflow: scroll;
    transition: all .5s;
    .user {
      //box-shadow: 0px 4px 20px 0px #e5ecf0;
      border-bottom: solid 1px #e5ecf0;
    }
  }
}
</style>