<template>
  <div class="time-bubble" v-if="show">{{uiModel.time}}</div>
</template>

<script lang="ts" setup name="TimeBubble">
  import {defineProps, withDefaults, ref} from "vue";

  import {useTimeFormat} from "@hview/hview-vue3/packages";
  const {timeFormat} = useTimeFormat();
  interface Props {
    index: number;
    // 当前的数据
    data: object;
    // 上一条数据
    prevData?: object;
    hasNext?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    hasNext: false
  });
  const show = ref<boolean>(false);
  const uiModel = ref({
    time: ""
  });


  const getTimeLabel = (createTime) => {
    let timeLabel = "";
    // 创建时间
    const createDate = new Date(createTime);
    // 获取当前时间
    const today = new Date();
    const hours = createDate.getHours();
    // 判断是否是今年，是今年走此case
    if (today.getFullYear() == createDate.getFullYear()) {
      // 判断是否是本月
      if (today.getMonth() + 1 == createDate.getMonth() + 1) {
        // 判断是否是今天
        if (today.getDate() == createDate.getDate()) {
          timeLabel = "";
        } else if (today.getDate() - 1 == createDate.getDate()) {
          timeLabel = "昨天";
        } else  {
          timeLabel = `${createDate.getMonth() + 1}月${createDate.getDate()}日`;
        }
      } else {
        timeLabel = `${createDate.getMonth() + 1}月${createDate.getDate()}日`;
      }
    } else {// 不是今年
      timeLabel = timeFormat(createDate, "yyyy年mm月dd日");
    }
    let time = "";
    if (hours >= 0 && hours <= 6) {
      time = "凌晨"
    } else if (hours > 6 && hours <= 9) {
      time = "早上"
    } else if (hours > 9 && hours <= 11) {
      time = "上午"
    }  else if (hours > 11 && hours <= 1) {
      time = "中午"
    } else if (hours > 1 && hours <= 18) {
      time = "下午"
    } else {
      time = "晚上"
    }
    return `${timeLabel} ${time}${timeFormat(createDate, "hh:MM")}`;
  }
  const init = () => {
    // 有下一条
    if (props.prevData){
      const currentTime = props.data.createtime;
      const prevTime = props.prevData.createtime;
      if ((currentTime - prevTime) / 60 > 5 || (currentTime - prevTime) / 60 < -5) {
        show.value = true;
        uiModel.value.time = getTimeLabel(parseInt(currentTime) * 1000);
      }
    } else if (!props.prevData && !props.hasNext) {// 没有下一条，并且也是最后一条
      const currentTime = props.data.createtime;
      show.value = true;
      uiModel.value.time = getTimeLabel(parseInt(currentTime) * 1000);
    }
  }

  init();
</script>


// 是否有最后一条
hasNext: {
type: Boolean,
default: false
}

<style lang="scss">
.time-bubble {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-bottom: 10px;
}
</style>