"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var chat = [{
        path: '/chat',
        meta: {
            title: "聊天"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/chat/chat.vue"); }); }
    }, {
        path: '/chat/common-words',
        meta: {
            title: "常用语管理",
            activeMenu: "/chat"
        },
        component: function () { return Promise.resolve().then(function () { return require("@/views/chat/common-words-list.vue"); }); }
    }];
exports.default = chat;
