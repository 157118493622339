<template>
  <h-container isBack isTable :title="uiModel.title">
    <el-form ref="formRef" :model="model" :inline="true" label-width="auto">
      <el-form-item label="用户ID" prop="userId">
        <el-input v-model="model.userId" placeholder="请输入用户ID" />
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="model.name" placeholder="请输入真实姓名或昵称" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="reload(load)">查询</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="uiModel.loading" :data="uiModel.list" stripe style="width: 100%">
      <el-table-column header-align="center" align="center" prop="title" label="表单ID" width="100">
        <template #default="scope">
          {{scope.row.customform.id}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="title" label="表单名称" width="220">
        <template #default="scope">
          {{scope.row.customform.title}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="title" label="用户ID" width="180">
        <template #default="scope">
          {{scope.row.user_id}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="title" label="用户名称" width="180">
        <template #default="scope">
          {{scope.row.realname || scope.row.nickname}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="createtime" label="表单填写时间" min-width="180">
        <template #default="scope">
          {{timeFormat(scope.row.createtime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" prop="updatetime" label="表单修改时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.updatetime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" header-align="center" fixed="right" width="100">
        <template #default="scope">
          <div class="flex align-items-center">
            <el-button type="primary" link @click="toResultPage(scope.row)">查看详细</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="h-pagination">
        <h-pagination @change="paginationChangeEvent" :current-page="page" :total="totalRow" />
      </div>
    </template>
  </h-container>
</template>

<script lang="ts" setup>
import {usePagination} from "@hview/hview-vue3/packages";
import {useRouter, useRoute} from "vue-router";
import {ref, onMounted} from "vue";
import {useTimeFormat} from "@hview/hview-vue3/packages";
import {getFormInfoApi, getFromUserResultListApi} from "@/api/doctor";
const {updatePage, getPagination, totalRow, update, reload, page, correctPage} = usePagination();
const router = useRouter();
const {timeFormat} = useTimeFormat();
const uiModel = ref({
  title: "",
  loading: false,
  list: []
});

const model = ref({
  id: "",
  userId: "",
  name: ""
});

const route = useRoute();

const formRef = ref();

const resetForm = () => {
  formRef.value.resetFields();
  reload(load);
}
/**
 * @description 页码改变事件
 */
const paginationChangeEvent = (value: number) => {
  update(value, load);
}

/**
 * @description 去填写结果页面
 */
const toResultPage = (data: any) => {
  router.push(`/form/result?id=${data.id}&fid=${data.customform.id}`);
}


/**
 * @description 加载数据
 */
const load = () => {
  uiModel.value.loading = true;
  getFormInfoApi({id: model.value.id}).then((res: any) => {
    console.info(res);
    uiModel.value.title = `【表单结果】${res.data.title}`;
  });
  getFromUserResultListApi({...model.value,...getPagination()}).then((res: any) => {
    const data = res.data;
    updatePage(data);
    uiModel.value.list = data.data;
  }).finally(() => {
    uiModel.value.loading = false;
  });
}

onMounted(() => {
  const id = route.query.id || "";
  if (id) {
    model.value.id = id;
    load();
  }
});

</script>

<style scoped>

</style>