<!--
  @description: 患者指标
-->
<template>
  <el-drawer v-model="drawer" title="关注指标" size="600" >
    <div v-loading="uiModel.loading">
      <el-empty description="暂无指标数据" v-if="!uiModel.loading && uiModel.zhibiao.length == 0">
        <el-button type="primary" size="large" @click="drawer = false">关闭</el-button>
      </el-empty>
      <div v-else>
        <el-table :data="uiModel.zhibiao">
          <el-table-column prop="project_name" label="项目" width="180" />
          <el-table-column prop="name" label="结果" width="180">
            <template #default="scope">
              <div class="flex">
                <span class="font-size-16 color-red font-weight-bold font-style-italic">{{parseFloat(scope.row.result).toFixed(1)}}</span>
                <span class="font-size-14 m-l-4">{{ scope.row.unit }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="record_date" label="更新时间" />
        </el-table>
      </div>
    </div>
  </el-drawer>
</template>

<script lang="ts" setup name="PatientIndicatorsDrawer">
  import {ref, defineExpose} from "vue";
  import {getPatientDataApi} from "@/api/chat";
  const drawer = ref<boolean>(false);
  const uiModel = ref({
    loading: false,
    zhibiao: []
  });

  /**
   * @description 加载关注指标
   * @param userId
   */
  const loadPatientData = (userId: string | number) => {
    uiModel.value.loading = true;
    getPatientDataApi({
      userId: userId
    }).then((res: any) => {
      const {zhibiaolist} = res.data;
      uiModel.value.zhibiao = zhibiaolist;
    }).finally(() => {
      uiModel.value.loading = false;
    });
  }

  const open = (userId: string | number) => {
    drawer.value = true;
    loadPatientData(userId);
  }

  defineExpose({
    open
  });
</script>

<style lang="scss">

</style>