<!--
  @description：对话窗口
  @author zhaxianhe
  @version v1.0
-->
<template>
  <div class="dialogue-window flex flex-direction-column h-100p">
    <!-- 对话头部 -->
    <div class="dialogue-window-header flex align-items-center p-l-10 p-r-10">
      <div class="font-size-18" v-if="fromUserInfo">{{fromUserInfo.realname || fromUserInfo.nickname}}</div>
      <div class="font-size-18" v-if="groupInfo">
        <el-tag size="small">客服群</el-tag>
        {{groupInfo.name}}-{{groupInfo.team.name}}
      </div>
    </div>
    <!-- 对话内容部分 -->
    <div class="dialogue-window-message flex-1" v-loading="maskLoading" element-loading-text="加载中...">
      <el-scrollbar class="h-100p rotate" >
        <div class="flex-1"></div>
        <div class="p-t-20 p-b-20 p-l-15 p-r-15" v-infinite-scroll="load">
            <message-view :list="messageList" :hasNext="hasNext"></message-view>
        </div>
      </el-scrollbar>
    </div>
    <!-- 发送部分 -->
    <div class="dialogue-window-sendbox h-170 flex flex-direction-column">
      <div class="h-40 flex align-items-center tools">
        <!-- 表情 -->
        <el-tooltip effect="dark" content="表情" placement="top">
          <div ref="emojiRef" class="iconfont icon-biaoqing icon cursor-pointer"></div>
        </el-tooltip>
        <!-- 图片 -->
<!--        <el-tooltip effect="dark" content="图片" placement="top">-->
<!--          <el-upload ref="uploadRef"-->
<!--                     action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"-->
<!--                     :show-file-list="false"-->
<!--                     accept="image/*"-->
<!--                     :auto-upload="true">-->
<!--            <template #trigger>-->
<!--              <div class="iconfont icon-tupian icon cursor-pointer"></div>-->
<!--            </template>-->
<!--          </el-upload>-->
<!--        </el-tooltip>-->
        <!-- 常用语 -->
        <el-tooltip effect="dark" content="常用语" placement="top">
          <div ref="commonPhrasesRef" class="iconfont icon-changyongyu icon cursor-pointer"></div>
        </el-tooltip>



      </div>
      <div class="flex-1 p-5">
        <textarea ref="inputRef" v-model="message" @keydown="handleKeyCode($event)" placeholder="请输入消息内容，按Enter发送..." class="h-120 message-box"/>
      </div>
    </div>

    <!-- 表情 -->
    <el-popover width="300" ref="emojiPopoverRef" :virtual-ref="emojiRef" placement="top" trigger="click"  virtual-triggering>
      <Vue3EmojiPicker class="chat-emoji" :hide-search="true" :disable-sticky-group-names="true" :disable-skin-tones="true" :hide-group-names="true" v-model="selectedEmoji" @select="onEmojiSelect" />
    </el-popover>
    <!-- 常用语 -->
    <el-popover @show="loadCommonWords" width="500" ref="commonWordsPopoverRef" :virtual-ref="commonPhrasesRef" placement="top" trigger="click"  virtual-triggering>
      <div v-loading="commonWordsLoading" class="common-words">
        <div class="common-words-header flex align-items-center justify-content-space-between m-b-20">
          <span class="font-size-18 font-weight-bold">我的常用语</span>
          <el-button type="primary" link @click="toCommonWordsPage">去管理</el-button>
        </div>
        <div>
          <div v-if="commonWordsList.length == 0">
            <el-empty description="还没有常用语，快去创建吧～">
              <el-button type="primary">创建常用语</el-button>
            </el-empty>
          </div>

          <div class="common-words-item cursor-pointer" v-for="(item) in commonWordsList" :key="item.id" @click="setCommonWords(item)">
            {{item.content}}
          </div>
        </div>
      </div>
    </el-popover>
    <ai-reply-dialog ref="aiReplyDialogRef"/>
  </div>
</template>

<script lang="ts" setup name="Dialogue">
  import {ref, onUnmounted,onMounted, watch} from "vue";
  // emoji组件
  import Vue3EmojiPicker from 'vue3-emoji-picker';
  import 'vue3-emoji-picker/css';
  import {useRouter, useRoute} from "vue-router";
  import { ElMessageBox } from "element-plus";
  // import type { UploadInstance } from 'element-plus'
  import {
    getCommonWordsApi,
    getGroupHistroyListApi,
    getMessageHistoryApi,
    getTeamGroupTalkInfoApi,
    messageActApi
  } from "@/api/chat";
  import MessageView from "./message-view.vue";
  import {getUserInfoApi} from "@/api/addons";
  import { ElMessage } from 'element-plus'
  import UserCache from "@hview/hview-utils/lib/cache/user";
  import {useImagePath} from "@/hooks/image-path";
  import {MessageType} from "@hview/hview-lib/lib/socket";
  import {Message, mitter} from "@hview/hview-vue3/packages";
  import {AiReplyDialog} from "@/components/views/chat";

  const router = useRouter();
  const route = useRoute();
  const {cdnpath} = useImagePath();
  const message = ref("");
  const loading = ref<boolean>(false);
  let selectedEmoji = ref('');

  const socket = (window as any).$scoket;
  /**
   * 常用语加载状态
   */
  const commonWordsLoading = ref<boolean>(false);
  /**
   * 文件上传ref
   */
  // const uploadRef = ref<UploadInstance>();

  /**
   * @description 常用语列表
   */
  const commonWordsList = ref([]);

  const maskLoading = ref<boolean>(false);

  const commonPhrasesRef = ref();
  const emojiRef = ref();

  const emojiPopoverRef = ref();

  const commonWordsPopoverRef = ref();

  const aiReplyDialogRef = ref();

  /**
   * 输入框ref
   */
  const inputRef = ref();
  /**
   * 请求数据
   */
  const model = ref({
    from: "",
    page: 1
  });

  /**
   * 个人信息
   */
  const fromUserInfo = ref(null);

  /**
   * 群组信息
   */
  const groupInfo = ref(null);

  /**
   * 消息列表
   */
  const messageList = ref([]);

  /**
   * 是否有下一条
   */
  const hasNext = ref<boolean>(false);


  /**
   * 加载数据,判断消息类型，是群组还是个人
   */
  const load = () => {
    const id = route.query.id || "";
    if (!id) {
      return;
    }
    if (hasNext.value) {
      model.value.page++;
      if (id.indexOf("service") != -1) {
        loadGroupMessageList();
      } else {
        loadMessageList();
      }
    }
  }

  /**
   * @description emoji表情选中触发事件
   */
  const onEmojiSelect = (emoji: any) => {
    if (inputRef.value) {
      inputRef.value.focus();
      const start = inputRef.value.selectionStart;
      const end = inputRef.value.selectionEnd;
      const text = inputRef.value.value;
      message.value = `${text.slice(0, start)}${emoji.i}${text.slice(end)}`;
      emojiPopoverRef.value.hide();
    }
    selectedEmoji.value = '';
  }

  // const selectFile = () => {
  //   console.info(uploadRef.value)
  // }

  /**
   * @description 跳转到常用语管理界面
   */
  const toCommonWordsPage = () => {
    router.push("/chat/common-words");
  }

  /**
   * @description 加载常用语
   */
  const loadCommonWords = () => {
    commonWordsLoading.value = true;
    getCommonWordsApi().then((res: any) => {
      const {data} = res;
      commonWordsList.value = data;
    }).finally(() => {
      commonWordsLoading.value = false;
    });
  }

  const setCommonWords = (data: any) => {
    message.value = data.content;
    commonWordsPopoverRef.value.hide();
  }

  /**
   * @description 回车发送消息
   */
  const handleKeyCode = (event: any) => {
    if (event.keyCode == 13) {
      // 直接发送
      if (!event.metaKey) {
        event.preventDefault();
        if (!message.value) {
          ElMessage.error("内容不能为空");
          return;
        }
        sendEvent({
          type: MessageType.TEXT,
          content: message.value
        }).then(() => {
          message.value = "";
        }).catch(() => {
          ElMessage.error("发送失败");
        });
      } else { // 回车
        message.value += "\n";
      }
    }
  }

  /**
   * @description 加载个人消息列表
   */
  const loadMessageList = () => {
    loading.value = true;
    getMessageHistoryApi(model.value).then((res: any) => {
      const data = res.data;
      hasNext.value = data.hasmore;
      messageList.value.push(...data.list);
    }).finally(() => {
      loading.value = false;
      maskLoading.value = false;
    });
  }

  /**
   * @description 加载群组消息
   */
  const loadGroupMessageList = () => {
    getGroupHistroyListApi(model.value).then((res: any) => {
      const data = res.data;
      hasNext.value = data.hasmore;
      messageList.value.push(...data.list);
    }).finally(() => {
      loading.value = false;
      maskLoading.value = false;
    });
  }


  /**
   * @description 查询对方用户信息
   */
  const getUserInfo = () => {
    getUserInfoApi({
      id: model.value.from
    }).then((res: any) => {
      fromUserInfo.value = res.data;
    });
  }

  const getGroupInfo = () => {
    const id = route.query.id || "";
    if (!id) {
      return;
    }
    const groupId = id.split("_")[1];
    getTeamGroupTalkInfoApi({
      groupId: groupId
    }).then((res: any) => {
      groupInfo.value = res.data;
    });
  }



  /**
   * @description 初始化数据
   */
  const init = () => {
    sendReadEvent();
    groupInfo.value = null;
    fromUserInfo.value = null;
    // 从路由获取id
    const id = route.query.id || "";
    if (!id) {
      return;
    }
    messageList.value = [];
    model.value.from = id;
    model.value.page = 1;
    maskLoading.value = true;
    // 如果是群组，则加载群组消息
    if (id.indexOf("service") != -1) {
      getGroupInfo();
      loadGroupMessageList();
    } else {
      getUserInfo();
      loadMessageList();
    }
  }

  watch(route, () => {
    init();
  });

  const globalDataListener = (globalData) => {
    if (globalData.type == "msg") {
      const {data} = globalData;
      if (data.type ===  "action" || data.type === "system") {
        // TODO
      } else {
        if ((data.from_user_id == model.value.from && data.to_user_id == UserCache.getUserInfo().id) || (data.to_user_id == model.value.from && data.from_user_id == UserCache.getUserInfo().id)) {
          data.from_avatar = cdnpath(data.from_avatar);
          messageList.value.unshift(data);
          // 对方发送消息，滚动到最底部
          // from_user_id与当前页面到id参数匹配，代表当前聊天界面为与对方聊天，对方发送消息时，立刻发送已读状态
          if (data.from_user_id == model.value.from) {
            sendReadEvent();
          }
        }
      }
    }
  }

  /**
   * @description 发送已读消息
   */
  const sendReadEvent = () => {
    sendEvent({
      type: MessageType.ACTION,
      content: {
        operation: "read"
      }
    });
  }

  /**
   * @description 发送事件
   */
  const sendEvent = (data: any) => {
    return new Promise(((resolve, reject) => {
      // 组装消息参数
      let messageData = {
        type: data.type
      };
      // 添加消息内容
      messageData["content"] = data.content;
      // 添加接收人
      messageData["to"] = route.query.id;
      // 先判断当前连接是否断开
      if (!socket.getSocketStatus()) {
        ElMessageBox.confirm(
            '为了保证及时收到消息，请重新连接！',
            '连接中断',
            {
              confirmButtonText: '重新连接',
              cancelButtonText: '暂不连接',
              type: 'warning',
            }
        ).then(() => {
          socket.connect();
        }).finally(() => {
          this.isShowConfirm = false;
        });
        reject("连接已断开");
      }
      const queryId = route.query.id || "";
      if (!queryId) {
        return;
      }
      if (queryId.indexOf("service") != -1) {
        socket.sendGroupMsg(messageData).then(res => {
          resolve(res);
        }).catch(e => {
          reject(e);
        });
      } else {
        socket.sendPrivateMsg(messageData).then(res => {
          resolve(res);
        }).catch(e => {
          reject(e);
        });
      }
    }));
  }

  /**
   * @description 监听让ai回复
   * @param text
   */
  const onAiReply = (text: string) => {
    aiReplyDialogRef.value.open(text);
  }
  const onRevert = (data: any) => {
    console.info(data)
      let messageData = {
        type: MessageType.ACTION,
        content: {
          operation: 'revert',
          id: data.id,
          content: data.content
        }
      };
      sendEvent(messageData).then(() => {
        for (let i = 0; i < messageList.value.length; i++) {
          let item: any = messageList.value[i];
          if (item.id == data.id) {
            item.isrevert = 1;
            break;
          }
        }
      });
  }

  const onDeleteMsg = (id: any) => {
    ElMessageBox.confirm(
        '是否删除?',
        '提示',
        {
          type: 'warning',
        }
    ).then(() => {
      messageActApi({
        act: 'del',
        id: id
      }).then(() => {
        Message.success("删除成功");
        for (let i = 0; i < messageList.value.length; i++) {
          let item = messageList.value[i];
          if (item.id == id) {
            messageList.value.splice(i, 1);
            break;
          }
        }
      });
    });
  }

  /**
   * @description 监听事件
   * @param data
   */
  const onSendMsg = (data: any) => {
    // 删除消息
    if (data.type == "deleteMsg") {
      onDeleteMsg(data.data.id);
      return;
    }
    // ai回复
    if (data.type == "aiReply") {
      onAiReply(data.data.content);
      return;
    }
    // 撤回
    if (data.type == "revert") {
      onRevert(data.data);
      return;
    }


  }

  // 监听全局样式
  window.microApp.addGlobalDataListener(globalDataListener);

  onUnmounted(() => {
    window.microApp.removeGlobalDataListener(globalDataListener);
    mitter.off("on-send-msg");
  });

  onMounted(() => {
    init();
    mitter.on("on-send-msg", onSendMsg);
  });
</script>

<style lang="scss">
.chat-emoji {
  &.v3-emoji-picker {
    box-shadow: none !important;
    .v3-footer {
      display: none !important;
    }
  }
}

.rotate {
  transform: rotate(180deg);
}
  .dialogue-window {
    .el-scrollbar__view {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &-header {
      //box-shadow: 0px 1px 11px 0px #e5ecf0;
      border-bottom: solid 1px #e5ecf0;
      height: 60px;
    }
    &-message {
      overflow: hidden;
      .el-scrollbar__bar {
        left: 2px;
      }
    }
    &-sendbox {
      border-top: solid 1px #e5ecf0;
      .tools {
        .icon {
          font-size: 22px;
          padding: 0 8px;
          color: var(--font-secondary-color);
        }
      }
      .message-box {
        resize: none;
        padding: 5px 11px;
        line-height: 1.5;
        box-sizing: border-box;
        width: 100%;
        font-family: inherit;
        color: var(--el-text-color-regular);
        background-color: var(--el-fill-color-blank);
        background-image: none;
        -webkit-appearance: none;
        border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
        transition: var(--el-transition-box-shadow);
        border: none;
        font-size: 16px;
        &:focus {
          outline: none;
          box-shadow: 0 0 0 1px var(--primary-color) inset;
        }
      }
    }
  }
  .common-words {
    max-height: 300px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    &-header {
      //height: ;
    }
    &-item {
      padding: 8px 0px;
      font-size: 16px;
      border-bottom: solid 1px var(--line-color-2);
      &:last-child {
        border-bottom: none;
      }
    }
  }
</style>