<template>
  <h-container frameless>
    <v-form-designer ref="vfDesigner" :field-list-api="fieldListApi">
        <el-button type="primary" @click="saveForm">暂存</el-button>
        <el-button type="primary" @click="saveForm('close')">保存并返回</el-button>
    </v-form-designer>


  </h-container>
</template>

<script lang="ts" setup>
  import { reactive, ref } from "vue";
  import {useRoute, useRouter} from "vue-router";
  import {getApiPath} from "@config/application";
  import UserCache from "@hview/hview-utils/lib/cache/user";
  import {getFormInfoApi, updateFormApi} from "@/api/doctor";
  import {Notice} from "@hview/hview-vue3/packages";
  const route = useRoute();
  const router = useRouter();
  const vfDesigner = ref(null);

  const loading = ref<boolean>(false);

  const fieldListApi = reactive({
    URL: `${getApiPath()}/api/v2/doctor/Customform/FormZhibaoList`,
    labelKey: 'fieldLabel',
    nameKey: 'fieldName',
    headers: {
      token: UserCache.getToken()
    }
  });

  /**
   * @description 保存表单
   */
  const saveForm = (type?: string) => {

    updateFormApi({
      id: route.params.id,
      content: JSON.stringify(vfDesigner.value.getFormJson())
    }).then((res: any) => {
      Notice.success("保存成功");
      if (type == "close") {
        router.push("/form");
      }
    });
  }

  /**
   * @description 加载表单
   */
  const load = () => {
    console.log(vfDesigner)
    const id = route.params.id;
    loading.value = true;
    getFormInfoApi({id: id}).then((res: any) => {
      console.log(res)
      let content = res.data.content || null;
      if (content) {
        content = content.replace(/&quot;/g,"\"");
        vfDesigner.value.setFormJson(JSON.parse(content));
      } else {
        vfDesigner.value.setFormJson({
          widgetList: [],
          formConfig: {}
        });

      }

    }).finally(() => {
      loading.value = false;
    });
  }

  load();
</script>

<style lang="scss">
  .toolbar-container {
    flex: 1 !important;
  }
  .main-container {
    overflow-y: scroll !important;
    .main-header {
      display: none;
    }
    .color-svg-icon {
      color: initial !important;
    }
    .widget-collapse {
      .field-widget-item:hover, .container-widget-item:hover {
        outline: solid 1px var(--primary-color) !important;
      }
    }

    .el-form-item.selected, .static-content-item.selected {
      outline: solid 1px var(--primary-color) !important;
    }
    .field-wrapper .field-action {
      background: var(--primary-color) !important;
    }
    .background-opacity {
      background: rgba(var(--primary-color-rgb, 0.6));
    }
    .field-wrapper .drag-handler:hover {
      background: var(--primary-color) !important;
    }

  }
</style>