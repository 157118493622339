<!--
  @description: 项目弹窗
-->
<template>
  <el-dialog :close-on-click-modal="false" :title="title" v-model="visible" width="750">
    <div v-loading="loading">
      <template v-if="model.id">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="项目ID">
            {{uiModel.id}}
          </el-descriptions-item>

          <el-descriptions-item width="130" label="项目名称">
            {{uiModel.project_name}}
          </el-descriptions-item>

          <el-descriptions-item span="2" label="状态">
            <h-dict mode="text" dictKey="status_type" v-model="uiModel.status"/>
          </el-descriptions-item>

          <el-descriptions-item width="130" label="项目描述">
            {{uiModel.project_desc}}
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <!-- 新增项目 -->
      <template v-else>
        <el-form ref="formRef" :model="model" :rules="rules">
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="model.name" placeholder="请输入项目名称"/>
          </el-form-item>
          <el-form-item label="项目描述" prop="description">
            <el-input type="textarea" :rows="5" v-model="model.description" placeholder="请输入项目描述"/>
          </el-form-item>
        </el-form>
      </template>
    </div>
    <template #footer>
      <div>
        <el-button v-if="model.id" type="primary" @click="visible = false">关闭</el-button>
        <template v-else>
          <el-button @click="visible = false" link>关闭</el-button>
          <el-button :loading="loading" type="primary" @click="submit">创建</el-button>
        </template>

      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup name="ProjectDialog">
  import {defineExpose, ref, defineEmits} from "vue";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  import {createProjectApi, getProjectInfoApi} from "@/api/kh";
  import {Notice} from "@hview/hview-vue3/packages";
  const {visible, loading} = useApp();
  const title = ref("");

  const emits = defineEmits(["on-load"]);

  const formRef = ref();

  const model = ref({
    id: "",
    name: "",
    description: ""
  });

  const uiModel = ref({});


  const rules = ref({
    name: [{
      required: true,
      message: "请输入项目名称",
      trigger: ["blur", "change"]
    }],
    description: [{
      required: true,
      message: "请输入项目描述",
      trigger: ["blur", "change"]
    }]
  });

  /**
   * @description 加载数据
   */
  const load = () => {
    loading.value = true;
    getProjectInfoApi(model.value).then((res: any) => {
      uiModel.value = res.data;
    }).finally(() => {
      loading.value = false;
    });
  }

  /**
   * @description 打开弹窗
   */
  const open = (id: string) => {
    visible.value = true;
    title.value = "项目详情";
    model.value.id = id;
    load();
  }

  /**
   * @description 创建项目
   */
  const submit = () => {
    formRef.value.validate((valid: boolean) => {
      if (valid) {
        loading.value = true;
        createProjectApi(model.value).then(() => {
          Notice.success("创建成功");
          visible.value = false;
          emits("on-load");
        }).finally(() => {
          loading.value = false;
        });
      }
    });

  }

  /**
   * @description 创建项目
   */
  const create = () => {
    visible.value = true;
    model.value.id = "";
    title.value = "创建项目";
  }

  defineExpose({
    open,
    create
  });

</script>

<style scoped>

</style>