"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var packages_1 = require("@hview/hview-vue3/packages");
var form = [{
        path: '/form',
        component: packages_1.HRouterView,
        children: [{
                path: "",
                meta: {
                    title: "表单管理"
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/vform/list.vue"); }); }
            }, {
                path: ":id",
                meta: {
                    title: "编辑表单"
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/vform/vform.vue"); }); }
            }, {
                path: "submit",
                meta: {
                    title: "表单填写"
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/vform/submit.vue"); }); }
            }, {
                path: "submit-success",
                meta: {
                    title: "填写成功"
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/vform/submit-success.vue"); }); }
            }, {
                path: "complete",
                meta: {
                    title: "表单填写"
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/vform/complete-list.vue"); }); }
            }, {
                path: "result",
                meta: {
                    title: "表单结果"
                },
                component: function () { return Promise.resolve().then(function () { return require("@/views/vform/form-result.vue"); }); }
            }]
    }];
exports.default = form;
