<template>
  <div class="address-item cursor-pointer flex align-items-center">
    <div>
      <el-avatar class="w-40 h-40 m-r-10" :src="data.avatar || defautAvatar"/>
    </div>
    <div class="flex-1">{{data.realname || data.nickname}}</div>
  </div>
</template>

<script lang="ts" setup name="AddressItem">
import {defineProps, ref} from "vue";
const defautAvatar = ref("http://app.carecancer.cn/assets/img/avatar.png");
defineProps<{
  data: object
}>();
</script>

<style lang="scss">
.address-item {
  border-bottom:  solid 1px var(--line-color-1);
  padding: 10px 8px;
  transition: all .3s;
  &:hover {
    background: rgba(var(--primary-color-rgb), 0.1);
    border-radius: 10px;
  }
}
</style>