// @ts-ignore
import { createRouter, createWebHistory } from 'vue-router'
// @ts-ignore
import { MicroMessageType } from "@hview/hview-utils/lib/cache/micro";
// @ts-ignore
import MicroUtils from "@hview/hview-utils/lib/tools/micro-utils";
// @ts-ignore
import { useApp } from "@hview/hview-vue3/packages/hooks/use-app";
import routes from "@/router/routes";
const { fixBugForVueRouter4 } = useApp();

const router = createRouter({
  history: createWebHistory((window as any).__MICRO_APP_BASE_ROUTE__ || process.env.BASE_URL),
  routes
});
fixBugForVueRouter4(router);

router.beforeEach((to, from, next) => {
  MicroUtils.setGlobalData({
    type: MicroMessageType.ROUTE,
    data: {
      to,
      from,
      routes
    }
  });
  next();
});

export default router;
