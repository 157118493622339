<!--
  @description: 用户基本信息
-->
<template>
    <div class="user-info-wrapper flex flex-direction-column h-100p" v-loading="uiModel.loading">
      <div class="flex flex-direction-column justify-content-center user align-items-center p-b-10" v-if="uiModel.userInfo">
        <div class="w-50 h-50">
          <el-avatar :size="50" :src="cdnpath(uiModel.userInfo.avatar)"></el-avatar>
        </div>
        <div class="font-size-20 font-weight-bold m-t-10">{{uiModel.userInfo.realname || uiModel.userInfo.nickname}}</div>
      </div>
      <div class="flex-1" style="overflow:scroll;">
        <el-scrollbar class="h-100p p-10 box-sizing-border-box">
          <div class="h-item-title m-t-10">指标</div>
          <div class="m-b-10">
            <el-button type="primary" @click="openPatientIndicatorsDrawer">
              <div class="flex align-items-center">
                <i class="iconfont icon-baogao m-r-4"></i>
                <span>查看TA的指标</span>
              </div>
            </el-button>
          </div>
          <div class="h-item-title">基本信息</div>
          <el-descriptions :column="1" border size="large">
            <template v-for="(item, index) in uiModel.memberInfo" :key="index">
              <el-descriptions-item v-if="item.key == 'team'" width="80" label="加入社区" class-name="descriptions-team">
                <div class="item" v-for="(team, i) in item.value" :key="i">{{team.value}}</div>
              </el-descriptions-item>
              <template v-else>
                <el-descriptions-item v-if="item.value" width="80" :label="item.name" >{{ item.value }}</el-descriptions-item>
              </template>

            </template>

          </el-descriptions>

        </el-scrollbar>
      </div>
      <PatientIndicatorsDrawer ref="patientIndicatorsDrawer"/>
    </div>

</template>

<script lang="ts" setup name="UserInfo">
  import {ref, onMounted, watch} from "vue";
  import {useRoute} from "vue-router";
  import PatientIndicatorsDrawer from "./drawer/patient-indicators-drawer.vue";
  import {useImagePath} from "@/hooks/image-path";
  import {getMemberDataApi} from "@/api/doctor";
  import {getUserInfoApi} from "@/api/addons";
  const  route = useRoute();
  const {cdnpath} = useImagePath();
  const patientIndicatorsDrawer = ref();
  const model = ref({
    userId: ""
  });

  const uiModel = ref({
    memberInfo: [],
    userInfo: null,
    loading: false
  });


  /**
   * @description 查询memeber数据
   *
   */
  const getMemberData = () => {
    uiModel.value.loading = true;
    getMemberDataApi(model.value).then((res: any) => {
      const {member_info} = res.data;
      uiModel.value.memberInfo = member_info;
    }).finally(() => {
      uiModel.value.loading = false;
    });
  }

  /**
   * @description 加载用户信息
   */
  const getUserInfo = () => {
    getUserInfoApi({id: model.value.userId}).then((res: any) => {
      const info = res.data;
      uiModel.value.userInfo = info;
    });
  }

  watch(route, () => {
    init();
  });

  const init = () => {
    const id = route.query.id;
    if (!id) {
      return;
    }
    model.value.userId = id;
    getMemberData();
    getUserInfo();
  }

  onMounted(() => {
    init();
  });
  /**
   * @description 打开指标
   */
  const openPatientIndicatorsDrawer = () => {
    patientIndicatorsDrawer.value.open(model.value.userId);
  }
</script>

<style lang="scss">
.descriptions-team {
  padding: 0px !important;
  .item {
    padding: 15px;
    border-bottom: var(--el-descriptions-table-border);
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>