<template>
  <el-dialog :close-on-click-modal="false" title="查看二维码" v-model="visible" width="750">
    <div class="text-align-center">
      <vue-qrcode type="image/png" color="#333333" :value="qrString" width="350"></vue-qrcode>
    </div>
  </el-dialog>
</template>

<script lang="ts" setup name="QrDialog">
  import VueQrcode from 'vue-qrcode';
  import {ref, defineExpose} from "vue";
  import {getApplication} from "@config/application";
  import {useApp} from "@hview/hview-vue3/packages/hooks/use-app";
  const {visible} = useApp();
  const qrString = ref("https://www.baidu.com");


  const open = (value: any) => {
    visible.value = true;
    qrString.value = `${getApplication().baseurl}${getApplication().qrcodePrefix}/type=scanDoctorTeam&projectId=${value.id}&edc_proId=${value.edc_project_id}`;
    console.info(qrString.value)
  }

  defineExpose({
    open
  });
</script>

<style scoped>

</style>