import UserCache from "@hview/hview-utils/lib/cache/user";

/**
 * @description chat相关hooks
 */
export function useChat () {

    /**
     * @description 判断是否是我自己发送的
     * @param userId 对方的用户id
     */
    const isSender = (userId: string | number) => {
        const id = UserCache.getUserInfo().id;
        return id == userId;
    }

    return {
        isSender
    };
}