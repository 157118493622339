<!--
  @description: 常用语列表
-->
<template>
  <h-container isBack isTable title="我的常用语">
    <template #extra-header>
      <div class="flex-1 text-align-right">
        <el-button type="primary" @click="createCommonEvent">新增常用语</el-button>
      </div>
    </template>
    <el-table v-loading="loading" :data="list" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" header-align="center" align="center" width="100" />
      <el-table-column prop="content" label="常用语" min-width="180" />
      <el-table-column prop="createtime" label="创建时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.createtime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="updatetime" label="修改时间" width="180">
        <template #default="scope">
          {{timeFormat(scope.row.updatetime, "yyyy-mm-dd hh:MM:ss")}}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center" header-align="center" fixed="right" width="260">
        <template #default="scope">
          <el-button type="primary" @click="updateCommon(scope.row)" link>修改</el-button>
          <el-popconfirm @confirm="deleteCommon(scope.row)" title="删除后不可恢复，是否继续?" cancel-button-text="我再想想" confirm-button-text="确定" width="230">
            <template #reference>
              <el-button type="primary" link>删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <common-words-dialog ref="commonWordsDialogRef" @change="load"/>
  </h-container>
</template>

<script lang="ts" setup>
  import {ref, onMounted} from "vue";
  import {CommonWordsDialog} from "@/components/views/chat";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  import {Notice, useTimeFormat} from "@hview/hview-vue3/packages";
  import {delCommonWordsApi, getCommonWordsApi} from "@/api/chat";
  const {loading} = useApp();
  const {timeFormat} = useTimeFormat();

  const list = ref([]);

  const commonWordsDialogRef = ref();

  /**
   * @description 新增常用语
   */
  const createCommonEvent = () => {
    commonWordsDialogRef.value.create();
  }

  /**
   * @description 修改常用语
   */
  const updateCommon = (data: any) => {
    commonWordsDialogRef.value.update(data);
  }

  /**
   * @description 删除常用语
   */
  const deleteCommon = (data: any) => {
    loading.value = true;
    delCommonWordsApi({id: data.id}).then(() => {
      Notice.success("删除成功");
      load();
    }).finally(() => {
      loading.value = false;
    });
  }

  /**
   * @description 加载数据
   */
  const load = () => {
    loading.value = true;
    getCommonWordsApi().then((res: any) => {
      list.value = res.data;
    }).finally(() => {
      loading.value = false;
    });
  }

  onMounted(() => {
    load();
  })
</script>

<style scoped>

</style>