"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QrDialog = exports.AuditPatientDialog = exports.AuditDialog = exports.ProjectDialog = void 0;
// @ts-ignore
var project_dialog_vue_1 = require("./src/project-dialog.vue");
exports.ProjectDialog = project_dialog_vue_1.default;
var audit_dialog_vue_1 = require("./src/audit-dialog.vue");
exports.AuditDialog = audit_dialog_vue_1.default;
var audit_patient_dialog_vue_1 = require("./src/audit-patient-dialog.vue");
exports.AuditPatientDialog = audit_patient_dialog_vue_1.default;
var qr_dialog_vue_1 = require("./src/qr-dialog.vue");
exports.QrDialog = qr_dialog_vue_1.default;
