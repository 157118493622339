<template>
  <el-dialog :close-on-click-modal="false" v-model="visible" :title="uiModel.title" width="50%">
    <v-form-render ref="vFormRef">
    </v-form-render>
  </el-dialog>
</template>

<script lang="ts" setup name="VFormViewer">
  import {ref, defineExpose} from "vue";
  const visible = ref<boolean>(false);
  const vFormRef = ref(null);
  const uiModel = ref({
    title: "",
    formJson: {}
  });

  /**
   * @description 打开弹窗
   */
  const open = (data: any) => {
    uiModel.value.title = data.title;
    visible.value = true;
    const content = data.content.replace(/&quot;/g,"\"");

    setTimeout(() => {
      console.log(vFormRef)
      vFormRef.value.setFormJson(JSON.parse(content))
    }, 100);


  }


  defineExpose({
    open
  });
</script>

<style scoped>

</style>