<template>
  <div class="chat-tabs">
    <el-tabs v-model="chatTab" stretch>
      <el-tab-pane name="message">
        <template #label>
                <span class="flex align-items-center">
                  <i class="iconfont icon-xiaoxi m-r-6"></i>
                  <span>消息</span>
                </span>
        </template>
        <div class="full-message">
          <el-scrollbar class="h-100p p-10 box-sizing-border-box">
            <message-item v-for="(item, index) in messageList" :key="index" :data="item" :index="index" @click="messageEvent(item)"/>
          </el-scrollbar>
        </div>
      </el-tab-pane>
      <el-tab-pane name="address">
        <template #label>
          <span class="flex align-items-center">
            <i class="iconfont icon-tongxunlu m-r-6"></i>
            <span>通讯录</span>
          </span>
        </template>
        <div>
          <div class="p-10 address-search">
            <el-input v-model="model.keywords" size="large" placeholder="请输入搜索内容">
              <template #prefix>
                <el-select @change="selectEvent" v-model="model.teamId" class="w-75">
                  <el-option label="全部" value=""/>
                  <el-option v-for="(item) in teamList" :key="item.id" :label="item.name" :value="item.id"/>
                </el-select>
              </template>
              <template #suffix>
                <i class="iconfont icon-sousuo cursor-pointer" @click="searchMember"></i>
              </template>
            </el-input>
          </div>
          <div class="full-height" v-loading="loading">
            <el-scrollbar v-if="chatTab == 'address'" class="h-100p p-10 box-sizing-border-box" >
              <div v-infinite-scroll="loadMember">
                <address-item @click="addressEvent(item)" v-for="(item, index) in addressList" :key="index" :data="item"/>
                <div class="nomore" v-if="model.page > lastPage && addressList.length > 0">没有更多了</div>
                <el-empty v-if="addressList.length == 0 && !loading" :image-size="100" description="暂无联系人"/>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </el-tab-pane>
<!--      <el-tab-pane name="service">-->
<!--        <template #label>-->
<!--          <span class="flex align-items-center">-->
<!--            <i class="iconfont icon-tongxunlu m-r-6"></i>-->
<!--            <span>客服团队</span>-->
<!--          </span>-->
<!--        </template>-->
<!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script lang="ts" setup name="ChatMessage">
  // 聊天tab
  import {ref, onMounted, onUnmounted} from "vue";
  import {useRouter, useRoute} from "vue-router";
  import {getTeamListByUserApi, getTeamMemberInPCApi} from "@/api/team";
  import UserCache from "@hview/hview-utils/lib/cache/user";
  import AddressItem from "./address-item.vue";
  import MessageItem from "./message-item.vue";
  import {getMessageHomeListApi} from "@/api/chat";
  const router = useRouter();
  const route = useRoute();

  // 选项卡
  const chatTab = ref<string>("message");
  // 加载状态
  const loading = ref<boolean>(false);

  // team列表
  let teamList = ref([]);
  // 通讯录列表
  let addressList = ref([]);
  // 对话列表
  let messageList = ref([]);
  // 最后一页
  let lastPage = ref(1);
  let checkIndex = ref();
  // 查询条件
  const model = ref({
    teamId: "",
    keywords: "",
    page: 1
  });


  /**
   * @description 初始化team列表
   */
  const initTeam = () => {
    getTeamListByUserApi({
      userId: UserCache.getUserInfo().id
    }).then((res: any) => {
      teamList.value = res.data;
    });
  }


  /**
   * @description 搜索
   */
  const searchMember = () => {
    model.value.page = 1;
    addressList.value = [];
    loadMember();
  }

  /**
   * @description 加载通讯录
   */
  const loadMember = () => {
    if (model.value.page > lastPage.value) {
      return false;
    }
    loading.value = true;
    getTeamMemberInPCApi(model.value).then((res: any) => {
      const {data} = res;
      if (data.data.length > 0) {
        addressList.value.push(...data.data);
        model.value.page++;
        lastPage.value = data.last_page;
      }
    }).finally(() => {
      loading.value = false;
    });
  }


  /**
   * @description 下拉选择
   */
  const selectEvent = () => {
    model.value.page = 1;
    lastPage.value = 1;
    addressList.value = [];
    loadMember();
  }

  /**
   * @description 加载消息列表
   */
  const loadMessageHomeList = () => {
    getMessageHomeListApi().then(res => {
      const {data} = res;
      messageList.value = data;
      // 如果当前没有选中，默认选中第一个
      if (!route.query.id) {
        messageEvent(messageList.value[0]);
      }
    });
  }




  /**
   * @description 点击消息触发事件，通知到对话窗口
   * @param data
   */
  const messageEvent = (data: any) => {
    data.unread = 0;
    checkIndex.value = data.user.id;
    toChat(data.user.id);
  }

  const toChat = (id) => {
    router.replace({
      query: {
        id: id
      }
    });
  }

  /**
   * @description 点击通讯录触发事件，通知到对话窗口
   * @param data
   */
  const addressEvent = (data: any) => {
    toChat(data.user_id);
  }


  const globalDataListener = (globalData) => {
    if (globalData.type == "msg") {
      loadMessageHomeList();
    }
  }
  // 监听全局样式
  window.microApp.addGlobalDataListener(globalDataListener);

  onUnmounted(() => {
    window.microApp.removeGlobalDataListener(globalDataListener);
  });

  onMounted(() => {
    initTeam();
    loadMember();
    loadMessageHomeList();
  });
</script>

<style lang="scss">
.full-height {
  height: calc(var(--container-height) - 54px - 15px - 62px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.full-message {
  height: calc(var(--container-height) - 54px - 15px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.chat {
  padding-bottom: 10px;
  box-sizing: border-box;
  .address-search {
    .el-input__wrapper {
      padding-left: 4px !important;
    }
  }
  &-tabs {
    .el-tabs__header {
      margin: 5px 10px;
    }
    .el-tabs__nav-wrap:after {
      height: 1px;
      transform: scaleY(0.4);
    }
  }
}
.nomore {
  text-align: center;
  font-size: 12px;
  margin: 10px 0px;
  color: #333;
}
</style>