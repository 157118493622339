<template>
  <div class="h-editor" style="border: 1px solid #ccc">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        ref="toolbarRef"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        :style="{height: height + 'px'}"
        style="overflow-y: hidden;"
        v-model="valueHtml"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="handleCreated"
    />
  </div>
</template>

<script lang="ts" setup name="HEditor">
  import { onBeforeUnmount, ref, shallowRef, defineProps, withDefaults, watch, defineEmits, defineExpose } from 'vue'
  import '@wangeditor/editor/dist/css/style.css'; // 引入 css
  import { Boot } from '@wangeditor/editor'
  import { Editor, Toolbar } from '@wangeditor/editor-for-vue';

  const emits = defineEmits(["update:modelValue"]);

  const toolbarRef = ref();



  interface Props {
    modelValue: string;
    // 编辑器配置
    editorConfig?: object;
    // toolbar配置
    toolbarConfig?: object;
    mode?: string;
    height?: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    mode: "default",
    editorConfig: { placeholder: '请输入内容...' },
    toolbarConfig: {},
    height: 500
  });


  /**
   * 内容 HTML
   */
  const valueHtml = ref("");

  watch(() => props.modelValue, (value: string) => {
    valueHtml.value = value;

  });


  watch(valueHtml, (value: string) => {
    emits("update:modelValue", value);
  });


  /**
   * 编辑器实例，必须用 shallowRef
   */
  const editorRef = shallowRef();

  /**
   * @description 获取编辑器实例
   */
  const getEditorRef = () => {
    return editorRef.value;
  }
  // 组件销毁时，也及时销毁编辑器
  onBeforeUnmount(() => {
    const editor = editorRef.value
    if (editor == null) return
    editor.destroy()
  });

  const handleCreated = (editor) => {
    editorRef.value = editor // 记录 editor 实例，重要！
  }

  defineExpose({
    getEditorRef
  });
</script>

<style lang="scss">
.h-editor {
  border: solid 1px var(--line-color-1);
}
</style>