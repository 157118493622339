"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSid = void 0;
var axios_1 = require("axios");
var application_1 = require("@config/application");
var user_1 = require("@hview/hview-utils/lib/cache/user");
// import lodash from "lodash/debounce";
var lodash_1 = require("lodash");
var sid_1 = require("@hview/hview-utils/lib/cache/sid");
var packages_1 = require("@hview/hview-vue3/packages");
var micro_utils_1 = require("@hview/hview-utils/lib/tools/micro-utils");
var HttpInstance = /** @class */ (function () {
    function HttpInstance() {
        this.error = (0, lodash_1.debounce)(function (errMsg) {
            packages_1.Notice.error(errMsg);
        }, 1000, {
            leading: true,
            trailing: false
        });
        this.toLogin = (0, lodash_1.debounce)(function () {
            micro_utils_1.default.toLogin();
        }, 1000, {
            leading: true,
            trailing: false
        });
        this.instance = axios_1.default.create({
            baseURL: process.env.NODE_ENV === 'development' ? application_1.development.server.baseurl : application_1.prod.server.baseurl,
            timeout: 30000
        });
    }
    /**
     * @description 设置header头
     * @param header
     */
    HttpInstance.prototype.setHeader = function (header) {
        this.header = header;
        return this;
    };
    /**
     * @description 获取axios实例
     */
    HttpInstance.prototype.getInstance = function () {
        this.setRequestInterceptors();
        this.setResponseInterceptors();
        return this.instance;
    };
    /**
     * @description 设置请求前拦截器
     */
    HttpInstance.prototype.setRequestInterceptors = function () {
        var _this = this;
        this.instance.interceptors.request.use(function (config) {
            //获取缓存中的token
            config.headers["sid"] = sid_1.default.getSid();
            // @ts-ignore
            var tenant = user_1.default.getDefaultTenant() || null;
            if (tenant && tenant.length > 0) {
                config.headers["tenant"] = tenant[0] + "";
            }
            var token = user_1.default.getToken();
            if (token) {
                config.headers["token"] = token;
            }
            //判断是否有自定义header
            if (_this.header) {
                for (var key in _this.header) {
                    config.headers[key] = _this.header[key];
                }
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
    };
    /**
     * @description 设置响应后拦截器
     */
    HttpInstance.prototype.setResponseInterceptors = function () {
        var _this = this;
        this.instance.interceptors.response.use(function (response) {
            var data = response.data;
            //  成功
            if (data.code == 1) {
                return Promise.resolve(data);
            }
            // 失败
            if (data.code == 0) {
                _this.error(data.msg);
            }
            return Promise.reject(data);
        }, function (error) {
            var response = error.response;
            if (error.code == "ERR_NETWORK") {
                _this.error("网络异常，请稍后重试");
            }
            if (response && response.status === 401) {
                _this.toLogin();
                return;
            }
            return Promise.reject(error);
        });
    };
    return HttpInstance;
}());
exports.default = HttpInstance;
var setSid = function () {
};
exports.setSid = setSid;
