/**
 * @description 图片hooks
 *
 */
import {getApiPath} from "@config/application";

export function useImage() {


    /**
     * @description 获取图片地址
     * @param path
     */
    const getImagePath = (path: string) => {
        return `${getApiPath()}${path}`;
    }

    return {
        getImagePath
    };
}