import { RouteRecordRaw } from 'vue-router'

const team: Array<RouteRecordRaw> = [{
    path: '/team',
    meta: {
        title: "小组列表"
    },
    component: () => import("@/views/team/list.vue")
}, {
    path: '/team/:id',
    meta: {
        title: "小组管理",
        activeMenu: "/team"
    },
    component: () => import("@/views/team/manage.vue")
}, {
    path: '/team/announcement',
    meta: {
        title: "小组公告管理",
        activeMenu: "/team"
    },
    component: () => import("@/views/team/announcement/announcement-list.vue")
}, {
    path: '/team/announcement/:id',
    meta: {
        title: "小组公告管理",
        activeMenu: "/team"
    },
    component: () => import("@/views/team/announcement/announcement.vue")
}];
export default team;