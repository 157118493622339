<!--
  @description: 患者弹窗
-->
<template>
  <el-drawer v-model="visible" title="患者详情" size="600">
    <div v-loading="loading">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="基本信息" name="baseinfo">
          <el-descriptions :column="1" border size="large">
            <template v-for="(item, index) in uiModel.memberInfo" :key="index">
              <el-descriptions-item v-if="item.key == 'team'" width="80" label="加入社区" class-name="descriptions-team">
                <div class="item" v-for="(team, i) in item.value" :key="i">{{team.value}}</div>
              </el-descriptions-item>
              <template v-else>
                <el-descriptions-item width="80" :label="item.name" ><h-text v-model="item.value"/></el-descriptions-item>
              </template>

            </template>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="关注指标" name="zhibiao">
          <div v-loading="loading">
            <el-empty description="暂无指标数据" v-if="!loading && uiModel.zhibiao.length == 0">
            </el-empty>
            <div v-else>
              <el-table :data="uiModel.zhibiao">
                <el-table-column prop="project_name" label="项目" width="180" />
                <el-table-column prop="name" label="结果" width="180">
                  <template #default="scope">
                    <div class="flex">
                      <span class="font-size-16 color-red font-weight-bold font-style-italic">{{parseFloat(scope.row.result).toFixed(1)}}</span>
                      <span class="font-size-14 m-l-4">{{ scope.row.unit }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="record_date" label="更新时间" />
              </el-table>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script lang="ts" setup name="PatientDrawer">
  import {defineExpose, ref, watch} from "vue";
  import {useApp} from "@hview/hview-vue3/packages/hooks";
  import {getMemberDataApi} from "@/api/doctor";
  import {getPatientDataApi} from "@/api/chat";
  const {loading, visible} = useApp();

  const activeTab = ref<string>("baseinfo");

  watch(visible, (value) => {
    if (!value) {
      activeTab.value = "baseinfo";
    }
  });

  /**
   * 接口交互的model
   */
  let model = ref({
    userId: ""
  });

  /**
   * 页面渲染的ui
   */
  const uiModel = ref({
    memberInfo: [],
    zhibiao: []
  });

  /**
   * @description 打开弹窗
   */
  const open = (data) => {
    visible.value = true;
    model.value.userId = data.user_id;
    getMemberData();
    loadPatientData();
  }

  /**
   * @description 查询memeber数据
   *
   */
  const getMemberData = () => {
    loading.value = true;
    console.info(model.value)
    getMemberDataApi(model.value).then((res: any) => {
      const {member_info} = res.data;
      uiModel.value.memberInfo = member_info;
    }).finally(() => {
      loading.value = false;
    });
  }

  /**
   * @description 加载关注指标
   * @param userId
   */
  const loadPatientData = () => {
    loading.value = true;
    getPatientDataApi(model.value).then((res: any) => {
      const {zhibiaolist} = res.data;
      uiModel.value.zhibiao = zhibiaolist;
    }).finally(() => {
      loading.value = false;
    });
  }



  defineExpose({
    open
  });

</script>

<style scoped>

</style>