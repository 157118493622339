<!--
@description: 系统消息
@author: zhaxianhe
@createTime: 2023-07-19 11:43:47
-->
<template>
  <view class="system-bubble">
    <view v-html="parseSystemMessage()"></view>
  </view>
</template>

<script lang="ts" setup name="SystemBubble">
  import {defineProps} from "vue";
  import {useChat} from "@/components/views/chat/hooks/chat";
  const {isSender} = useChat();

  const props = defineProps<{
    data: object;
  }>();

  /**
   * @description 解析系统消息
   */
  const parseSystemMessage = () => {
    const content = JSON.parse(props.data.content);
    // 如果是普通文本消息
    if (content.operation == "text") {
      return content.content;
    }
    // 如果是答谢医生的消息
    if (content.operation == "thanks") {
      if (isSender(props.data.from_user_id)) {
        return `<span style="color: #1CB5A0;">您送给"${content.content.to}"一张"${content.content.gift}"福卡</span>`;
      } else {
        return `<span style="color: #1CB5A0;">"${content.content.from}"送给您一张"${content.content.gift}"福卡</span>`;
      }
    }
  }

</script>

<style lang="scss" >
.system-bubble {
  font-size: 15px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding: 20px 0px 10px 0px;
  text-align: center;
}
</style>
